import React from "react";
import { useSelector, useStore } from "react-redux";
import CampanhaService from "../../services/CampanhaService";
import Logo from "./navbar/Logo";
import { useLocation } from "react-router-dom";

/**
 * A lógica sempre será: pegar primeiro a logo da área logada, se ele NÃO EXISTIR mas existir a logo da área
 * deslogada então está é que terá que aparecer. Está lógica vale tanto para o mobile quanto para o desktop.
 *
 * A factory function to create different types of logos based on the user's login status and campaign settings.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.type - The type of logo to be created. It can be either 'mobile' or 'desktop'.
 *
 * @returns {JSX.Element} - A JSX element representing the logo based on the provided type.
 */
export default function LogoFactory({ type }) {
  const i18n = useStore().getState().i18n;
  const usuariologado = useSelector((state) => state.usuariologado);
  let location = useLocation();
  const campanhaService = new CampanhaService();
  let campanhaCorrente = campanhaService.campanhaCorrente();

  const locationPathname = location?.pathname;
  const isVirtualCampaignPage =
    campanhaService.isRecurrentCampaign() &&
    locationPathname === "/campaign/virtual";

  const altMessage = i18n.message(
    "alt.logo.principal.mobile",
    "Logo principal da empresa",
  );
  let to = "";
  let logo = "";
  let logoDesktop = "";
  let loggedInLogo = "";
  let loggedInLogoDesktop = "";
  let authAreaLogo = "";
  let authAreaLogoDesktop = "";

  if (usuariologado) {
    if (isVirtualCampaignPage) {
      to = "/campaign/virtual";
    } else {
      to = "/home";
    }
  } else {
    to = "/";
  }

  if (campanhaCorrente) {
    authAreaLogo = !loggedInLogo;
    authAreaLogoDesktop = !loggedInLogoDesktop;
    logo = campanhaCorrente.logoLeft;
    loggedInLogo = campanhaCorrente.presentationImage;
    // #region DESKTOP
    logoDesktop = campanhaCorrente.topLeftDesktop;
    loggedInLogoDesktop = campanhaCorrente.presentationDesktop;
    // #endregion DESKTOP
  }

  if (!type) {
    console.error(
      "Dev, é obrigatório informar um tipo de logo: mobile ou desktop.",
    );
    return;
  }

  if (type === "mobile") {
    if (loggedInLogo) {
      return (
        <Logo
          to={to}
          srcMobile={loggedInLogo}
          altMobile={altMessage}
          srcDesktop={loggedInLogoDesktop}
          altDesktop={altMessage}
        />
      );
    } else if (logo && authAreaLogo) {
      return (
        <Logo
          to={to}
          srcMobile={logo}
          altMobile={altMessage}
          srcDesktop={logoDesktop}
          altDesktop={altMessage}
        />
      );
    } else {
      return <Logo />;
    }
  }

  if (type === "desktop") {
    if (loggedInLogoDesktop) {
      return (
        <Logo
          to={to}
          srcMobile={loggedInLogo}
          altMobile={altMessage}
          srcDesktop={loggedInLogoDesktop}
          altDesktop={altMessage}
        />
      );
    } else if (logoDesktop && authAreaLogoDesktop) {
      return (
        <Logo
          to={to}
          srcMobile={logo}
          altMobile={altMessage}
          srcDesktop={logoDesktop}
          altDesktop={altMessage}
        />
      );
    } else {
      return <Logo />;
    }
  } else {
    return <Logo />;
  }
}
