export function removerPorPrefixo(prefixo) {
  // Obtém todas as chaves do localStorage
  const chaves = Object.keys(localStorage);

  // Filtra as chaves que começam com o prefixo especificado
  const chavesParaRemover = chaves.filter((chave) => chave.startsWith(prefixo));

  // Remove cada chave filtrada
  chavesParaRemover.forEach((chave) => {
    localStorage.removeItem(chave);
  });
}
