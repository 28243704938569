import instance from "./Api";
import CampanhaService from "./CampanhaService";

export default class CampanhaVirtualService {
  obterCampanhasVirtuais(searchFilters) {
    const config = {
      params: searchFilters,
    };
    const campanhaService = new CampanhaService();
    const clientUuid = campanhaService?.getClientUuid();
    return instance.get(
      this.host() + "/api/v1/events/client/" + clientUuid,
      config,
    );
  }

  groupEventsByTheme(events) {
    // Verifica se 'events' é um array válido
    if (!Array.isArray(events)) {
      return [];
    }

    const groupedEvents = Object.values(
      events.reduce((acc, event) => {
        // Usa optional chaining para evitar erros caso 'event' ou 'event.themeEvent' sejam null/undefined
        const { uuid, name, active, url, order } = event?.themeEvent || {};

        // Se não houver uuid, pula para o próximo evento
        if (!uuid) {
          return acc;
        }

        // Inicializa o acumulador para o tema, se necessário
        if (!acc[uuid]) {
          acc[uuid] = {
            themeEvent: {
              uuid,
              name: name || "Nome não disponível", // Valor padrão para name
              active: active || false, // Valor padrão para active
              url: url || "", // Valor padrão para url
              order: order || 0, // Valor padrão para order
              events: [], // Inicializa o array de eventos
            },
          };
        }

        // Adiciona o evento ao array de eventos do tema
        acc[uuid].themeEvent.events.push(event);
        return acc;
      }, {}),
    );

    // Ordena os temas pelo campo order
    return groupedEvents.sort(
      (a, b) => a.themeEvent.order - b.themeEvent.order,
    );
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }

  redirectToCampaign(code, enrollment) {
    return `${this.hostFrontend(code)}login?enrollment=${enrollment}`;
  }

  hostFrontend(code) {
    const host = process.env.REACT_APP_HOST_FRONTEND;
    const url = new URL(host);
    url.hostname = `${code}.${url.hostname}`;
    return url.toString();
  }
}
