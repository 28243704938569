import styled from "styled-components";

export const RoletaModalStyle = styled.dialog`
  .ql-size-huge {
    font-size: 1.625rem; // 26px;
  }

  .ql-size-large {
    font-size: 0.938rem; //15px;
  }

  .ql-size-small {
    font-size: 0.875rem; //14px;
  }

  width: 85vw;
  display: flex;
  margin: auto;
  flex-direction: column;
  border-radius: 8px !important;
  padding: 1rem;
  border: none;
  box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.17);

  .roleta-botao--zIndex {
    z-index: 100;
    position: relative;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  #confete svg {
    width: 88% !important;
    height: 70% !important;
    position: absolute;
    left: 0px;
  }

  .weexDialog__title {
    p {
      font-size: 1.625rem; //26px default. Mudar através de classes.
      font-weight: 700; // default. Mudar através de classes.
      line-height: 1.625rem; //26px default. Mudar através de classes.
      text-align: center; // default. Mudar através de classes.
      color: ${(props) => {
        return props.theme.TEXTO_TITULO;
      }};
    }
  }

  .weexDialog__subtitle {
    p {
      font-size: 1.188rem;
      line-height: 1.5rem;
      text-align: center;
      color: ${(props) => {
        return props.theme.TEXTO_SUBTITULO;
      }} !important;
    }
  }

  .weexDialog__texts {
    font-size: 1.188rem;
    line-height: 1.5rem;
  }

  .weexDialog__texts--alignment {
    text-align: center;
  }

  .weexDialog__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .weexDialog__header--ilustration {
    width: 65%;
  }

  .weexDialog__main {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .weexDialog__textsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .weexDialog__container--alignment {
    margin-top: 1.5rem;
  }

  .weexDialog__buttonsContainer,
  .weexDialog__stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  .weexDialog__buttonsContainer--direction {
    flex-direction: column;
    gap: 2rem;
  }

  .pontos-obtidos {
    font-size: 1.25rem;
  }

  .weexDialog__buttonsContainer--margins {
    margin-top: 1.5rem; //24px;
    margin-bottom: 1.5rem; //24px;
  }
  .weexDialog__buttonsContainer {
    flex-direction: column-reverse;
  }

  .weexDialog__buttonsContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 28.125rem;
    padding: 1rem;
    margin: auto;
    /*     .weexDialog__buttonsContainer--direction {
      flex-direction: row;
    } */

    .weexDialog__texts--alignment--marginsSquish {
      margin-left: 7rem;
      margin-right: 7rem;
    }
  }
  /* #endregion DESKTOP */
`;

export const RoletaGanhouCardStyle = styled.section`
  .ql-size-huge {
    font-size: 1.625rem; // 26px;
  }

  .ql-size-large {
    font-size: 0.938rem; //15px;
  }

  .ql-size-small {
    font-size: 0.875rem; //14px;
  }

  padding: 2rem; //32px
  border-radius: 0.75rem; //12px
  display: flex;
  flex-direction: column;
  gap: 2rem; //32px
  align-items: center;
  justify-content: center;

  .roleta-image-container img {
    /* max-width: 100%; */
    max-height: 8rem;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .roleta-image-container--premio img {
    max-width: 100%;
  }
  .roleta-texto {
    color: #005925;
  }

  .pontuacao-container {
    margin-top: 3vh;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(0, 226, 145, 0.1);
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pontuacao {
    color: #00e291 !important;
    text-align: center !important;
    font-weight: 800 !important;
  }

  .roleta-texto--pontuacao {
    text-align: center;
    font-weight: 800;
    line-height: 3rem;
    font-size: 3.75rem; //60px
  }

  .roleta-texto-descricao {
    text-align: center;
  }

  .roleta-voucher-link {
    border: 1px solid #005925;
    border-radius: 0.25rem; //4px
    padding: 0.75rem; //12px
  }

  .roleta-voucher-link--text {
    word-break: break-word;
    color: #005925;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;
