import styled, { keyframes } from "styled-components";

const zoomIn = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const AnimatedComponent = styled.div`
  animation: ${zoomIn} 0.15s linear;
`;

export const RoletaStyle = styled.section`
  @keyframes girarInfinitamente {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    display: block;
    margin: 0 auto;
  }

  .roleta-espaco-fatias {
    stroke: ${(props) => {
      return props.theme.FUNDO_ROLETA;
    }};
  }
  display: flex;
  align-items: center;
  justify-content: center;

  .roleta-bordas {
    border-color: ${(props) => {
      return props.theme.BORDA;
    }};
  }

  .roleta {
    position: relative; /* Define o contexto de posicionamento para os filhos */
    width: 16.438rem; //263px;
    height: 16.438rem; //263px;
  }

  .roleta-wheel-manivela-svg {
    transition: transform 2s ease-in-out;
  }

  .roleta-manivela-animation {
    svg {
      transform: rotate(39deg);
    }
  }

  .ql-size-huge {
    font-size: 1.625rem; // 26px;
  }

  .ql-size-large {
    font-size: 0.938rem; //15px;
  }

  .ql-size-small {
    font-size: 0.875rem; //14px;
  }

  .roleta-fatias-container,
  .roleta-fatias-textos-container {
    position: absolute; /* Ambos compartilham o mesmo contexto */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Para garantir que ambos fiquem circulares */
    overflow: hidden;
  }

  .roleta-fatia {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
  }

  .roleta-fatias-textos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(22deg);
  }

  .roleta-fatias-textos-container span {
    text-transform: uppercase;
    position: absolute;
    transform: translate(-50%, -50%);
    /* text-align: center; */
    letter-spacing: 0.028rem; // 0.45px;
    line-height: 1.313rem; // 21px;
  }

  * {
    box-sizing: border-box;
  }

  .roleta-texto-fatias {
    font-family: "DM Sans", Helvetica;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
  }

  .roleta-estrutura {
    background-color: transparent;
    height: 17.875rem; // 286px;
    overflow: hidden;
    position: relative;
    width: 17.25rem; // 276px;
  }

  .roleta-container {
    background-color: transparent;
    height: 16.438rem; //263px;
    left: 0.375rem; // 6px;
    position: absolute;
    top: 0.438rem; // 7px;
    width: 16.438rem; //263px;
  }

  .roleta-fundo {
    background-color: transparent;
    border: 1.125rem solid; //18px
    border-color: ${(props) => {
      return props.theme.FUNDO_ROLETA;
    }};
    border-radius: 8.219rem; // 131.5px;
    box-shadow:
      0px 4px 15.600000381469727px 3px #00000040,
      inset 0px 0px 3px 6px #0000002e;
    height: 16.438rem; //263px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 16.438rem; //263px;
  }

  .roleta-borda-fora {
    background-color: transparent;
    border: 0.125rem solid; //2px
    border-color: ${(props) => {
      return props.theme.BORDA;
    }};
    border-radius: 8.219rem; // 131.5px;
    height: 16.438rem; // 263px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 16.438rem; // 263px;
  }

  .roleta-borda-dentro {
    background-color: transparent;
    border: 0.125rem solid; //2px
    border-color: ${(props) => {
      return props.theme.BORDA;
    }};
    border-radius: 7.094rem; // 113.5px;
    height: 14.188rem; //227px;
    left: 1.125rem; //px;
    position: absolute;
    top: 1.125rem; //18px;
    width: 14.188rem; //227px;
  }

  .roleta-centro {
    background-color: ${(props) => {
      return props.theme.FUNDO_ROLETA;
    }};
    border-radius: 0.889rem; // 14.22px;
    box-shadow:
      inset 0px 0px 8.399999618530273px 3px #00000040,
      0px 0px 8.5px 6px #00000040,
      0px 0px 27.899999618530273px 36px #ffffff47;
    height: 1.75rem; // 28px;
    left: 7.313rem; // 117px;
    position: absolute;
    top: 7.313rem; //117px;
    width: 1.75rem; //28px;
  }
`;

export const SetaStyle = styled.svg`
  path {
    fill: ${(props) => {
      return props.theme.SETA;
    }};
  }
`;
export const ManivelaStyle = styled.svg`
  circle {
    stroke: ${(props) => {
      return props.theme.MANIVELA;
    }};
  }
`;

export const VoucherRoletaStyle = styled.div`
  .voucher-gestor {
    font-size: 1.25rem; //20px;
    font-weight: 700;
  }
`;
