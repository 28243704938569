import styled from "styled-components";

export const ThemeStyle = styled.div`
  border-radius: 1.25rem;
  background-color: rgba(227, 228, 227, 0.25);
  margin-top: 1rem;

  .themeStyle-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    h5 {
      font-size: inherit;
    }
  }

  .themeStyle-header__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2.375rem;
    max-width: 2.375rem;
    min-width: 2.375rem;
    height: 2.75rem;
    max-height: 2.75rem;
    min-height: 2.75rem;
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    border-radius: 0.5rem;
    background-color: ${(props) => {
      if (props.$habilitado === "true") {
        return props.theme.fundoSecundario;
      } else {
        return "#b7b7b7";
      }
    }};

    box-shadow: ${(props) => {
      return props.theme.sombraPrimaria;
    }};
  }

  .themeStyle__icon {
    max-width: 1.5rem; // 24px;
    max-height: auto;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    padding: 0.75rem;
  }
  /* #endregion DESKTOP */
`;
