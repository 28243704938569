import React from "react";
import { useStore, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import { MenuStyle } from "./style";
import BotaoTerciario from "../../button/BotaoTerciario";
import Exit from "../../svg/botoes/Exit";
import Trophy from "../../svg/botoes/Trophy";
import InviteFriends from "../../svg/botoes/InviteFriends";

const campanhaService = new CampanhaService();

/**
 * @param {Object} props
 * @param {function(): void} props.sair
 * @param {Date} props.now
 */
function Menu({ now, sair }) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const usuariologado = useSelector((state) => state.usuariologado);
  let location = useLocation();

  const campanhaCorrente = campanhaService.campanhaCorrente();
  const acceptLegalTerm = !!usuariologado?.legalTerm;
  const inicio = usuariologado ? "/home" : "/";
  const locationPathname = location?.pathname;
  const isVirtualCampaignPage =
    campanhaService.isRecurrentCampaign() &&
    locationPathname === "/campaign/virtual";
  const beginDate = campanhaService.getBeginDate();
  let tipoInscricao;
  let requiredInterests;
  let comoFunciona;
  let enableInvite;
  let campaignCode;
  if (campanhaCorrente) {
    campaignCode = campanhaCorrente?.code;
    tipoInscricao = campanhaCorrente.tipoInscricao;
    requiredInterests = campanhaCorrente.requiredInterests;
    comoFunciona = campanhaCorrente.comoFunciona;
    enableInvite = campanhaCorrente?.enableInvite;
  }

  const exporInicio =
    (tipoInscricao === "ELEGIBILIDADE" && !acceptLegalTerm) ||
    (requiredInterests === true &&
      locationPathname === "/interests" &&
      beginDate <= now);

  const exporComoFunciona =
    comoFunciona && comoFunciona.helpMessage && !isVirtualCampaignPage;

  const conquistas = () => {
    navigate("/trophy");
  };

  const inviteFriends = () => {
    navigate(`/invite/${campaignCode}`);
  };

  /*   const notificacoes = () => {
    navigate(`/notificacoes`);
  };
 */
  const isHome =
    locationPathname === "/home" ||
    locationPathname === "/atividades" ||
    locationPathname === "/";

  const isHelp = locationPathname === "/help";

  const home = () => {
    if (!exporInicio && campanhaCorrente?.code) {
      return (
        <li>
          <Link
            to={inicio}
            className="menuStyle__link menuStyle__link--dotContainer"
          >
            {i18n.message("geral.menu.inicio", "Início")}
            <div
              className={
                isHome
                  ? `menuStyle__link--dot menuStyle__link--dotActived`
                  : `menuStyle__link--dot`
              }
            />
          </Link>
        </li>
      );
    }
  };

  const howItWorks = () => {
    if (exporComoFunciona) {
      return (
        <li>
          <Link
            to="/help"
            className="menuStyle__link menuStyle__link--dotContainer"
          >
            {i18n.message("geral.menu.comoFunciona", "Como funciona")}
            <div
              className={
                isHelp
                  ? `menuStyle__link--dot menuStyle__link--dotActived`
                  : `menuStyle__link--dot`
              }
            />
          </Link>
        </li>
      );
    }
  };

  const invite = () => {
    if (enableInvite && !isVirtualCampaignPage) {
      return (
        <li>
          <BotaoTerciario
            size="small"
            funcao={inviteFriends}
            nome={i18n.message("geral.menu.chamarAmigos", "Chamar amigos")}
            ariaLabelMsg={i18n.message(
              "alt.convite.afiliados.icone",
              "Ícone silhueta de usuário com simbola de +",
            )}
            disabledOnClick={false}
            iconposition="left"
            iconLeft={
              <>
                <InviteFriends />
              </>
            }
          />
        </li>
      );
    }
  };

  const conquer = () => {
    if (!isVirtualCampaignPage) {
      return (
        <>
          <li>
            <BotaoTerciario
              size="small"
              funcao={conquistas}
              nome={i18n.message("geral.menu.conquistas", "Conquistas")}
              disabledOnClick={false}
              ariaLabelMsg={i18n.message(
                "alt.menu.sala.de.trofeus",
                "Ícone com acesso para a sala de conquistas",
              )}
              iconposition="left"
              iconLeft={
                <>
                  <Trophy />
                </>
              }
            />
          </li>
        </>
      );
    }
  };

  const out = () => {
    return (
      <li>
        <BotaoTerciario
          size="small"
          funcao={sair}
          nome={i18n.message("geral.menu.sair", "Sair")}
          disabledOnClick={false}
          iconposition="right"
          iconRight={
            <>
              <Exit />
            </>
          }
        />
      </li>
    );
  };

  return (
    <MenuStyle>
      <ul className="menuStyle__group menuStyle__group--link">
        {home()}
        {howItWorks()}
      </ul>
      <ul className="menuStyle__group menuStyle__group--btn">
        {invite()}
        {conquer()}
        {out()}
      </ul>
    </MenuStyle>
  );
}

export default Menu;
