import React, { useEffect, useRef, useState } from "react";
import { useSelector, useStore, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FormVideoFeedback from "../../comps/video/FormVideoFeedback";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import WeexModal from "../../comps/weexModais/WeexModal";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import { VideoStyled } from "./videoStyled";
import { VimeoVideo } from "../../comps/video/VimeoVideo";
import { Mp4Video } from "../../comps/video/MP4Video";
import { IframeVideo } from "../../comps/video/IframeVideo";

function Videos() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;

  let location = useLocation();

  const navigate = useNavigate();
  let { codigo, codigoAtividade } = useParams();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const [video, setVideo] = useState();
  const [finalizado, setFinalizado] = useState(false);
  const [bloquearBotao, setBloquearBotao] = useState(true);
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [vimeoCarregado, setVimeoCarregado] = useState(false);
  const [curtiuVideo, setCurtiuVideo] = useState(null);
  const [comentarioVideo, setComentarioVideo] = useState("");
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth);
  const campanhaService = new CampanhaService();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const effectRan = useRef(false);

  useEffect(() => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      dispatch({ type: "mainBackgroundColor", payload: "rgb(243, 243, 248)" });
      if (!processando) {
        setProcessando(true);
        if (codigo) {
          atividadesService.iniciarAtividade(
            codigo,
            codigoAtividade,
            (error, atividade) => {
              if (atividade) {
                if (
                  atividade?.video?.url?.indexOf("youtube") !== -1 ||
                  atividade?.video?.url?.indexOf("youtu.be") !== -1
                ) {
                  let regex =
                    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
                  let resultadoRegex = atividade.video.url.match(regex);
                  atividade.video.urlParseada =
                    "https://www.youtube.com/embed/" +
                    resultadoRegex[1] +
                    "?mute=0&controls=2&origin=" +
                    window.location.origin +
                    "&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" +
                    window.location.origin;
                }
                setCurtiuVideo(atividade.feedbackVideo);
                setVideo(atividade.video);
                setFinalizado(atividade.dateFinished !== null);
                setBloquearBotao(
                  atividade.dateFinished === null && isVimeo(atividade.video),
                );
                if (atividade.dateFinished !== null) {
                  agendaService.atualizarAgenda(
                    codigo,
                    codigoAtividade,
                    "VIDEO",
                    null,
                    null,
                    (errorAtualizarAgenda, sucesso) => {
                      if (error) {
                        setErro({
                          titulo: i18n.message(
                            "dinamica.erro.jaexecutada.titulo",
                            "Erro ao exibir atividade já executada",
                          ),
                          mensagem: i18n.message(
                            "dinamica.erro.jaexecutada.mensagem",
                            "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                          ),
                          voltarAgenda: true,
                        });
                      }
                    },
                  );
                }
              }
              if (error) {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  setErro({
                    titulo: i18n.message(
                      "dinamica.erro.iniciar.titulo",
                      "Erro ao iniciar atividade",
                    ),
                    mensagem: error.response.data.message,
                    voltarAgenda: true,
                  });
                } else {
                  setErro({
                    titulo: i18n.message(
                      "dinamica.erro.iniciar.titulo",
                      "Erro ao iniciar atividade",
                    ),
                    mensagem: i18n.message(
                      "dinamica.erro.iniciar.mensagem",
                      "Entre em contato com o suporte ou tente novamente mais tarde.",
                    ),
                    voltarAgenda: true,
                  });
                }
              }
            },
          );
        }
      }
    }

    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizeOfThings = () => {
    return window.screen.width;
  };
  window.addEventListener("resize", function () {
    setSizeScreen(sizeOfThings());
  });

  const callbackModal = () => {
    setErro(null);
    if (erro.voltarAgenda) {
      navigate("/atividades");
    }
  };

  const videoCarregado = () => {
    setVimeoCarregado(true);
  };

  const redirecionar = () => {
    finalizar();
  };

  const finalizar = () => {
    if (bloquearBotao) {
      return;
    }
    setFinalizado(true);
    setBloquearBotao(true);
    let feedbackVideo;
    let usuarioService = new UsuarioService();
    let usuarioCorrente = usuarioService?.usuarioCorrente();
    if (usuarioCorrente) {
      let uuid = usuarioService?.getCodigoParticipante();
      if (!uuid) {
        console.error("Codigo do participante não encontrado em Vídeos");
      }
      feedbackVideo = {
        participanteUuid: uuid,
        videoUuid: video && video.uuid,
        videoRelevante: curtiuVideo,
        comentario: comentarioVideo,
      };
    }
    let corpo = {
      feedbackVideo: feedbackVideo,
    };
    atividadesService.finalizarAtividade(
      codigo,
      codigoAtividade,
      "VIDEO",
      corpo,
      (error, atividade) => {
        if (atividade) {
          setBloquearBotao(false);
          navigate(`/missionFinished/${codigo}/VIDEO/${codigoAtividade}`, {
            state: atividade,
          });
          return;
        }
        if (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErro({
              titulo: i18n.message(
                "dinamica.erro.finalizar.titulo",
                "Erro ao finalizar atividade",
              ),
              mensagem: error.response.data.message,
              voltarAgenda: false,
            });
          } else {
            setErro({
              titulo: i18n.message(
                "dinamica.erro.finalizar.titulo",
                "Erro ao finalizar atividade",
              ),
              mensagem: i18n.message(
                "dinamica.erro.finalizar.mensagem",
                "Entre em contato com o suporte ou tente novamente mais tarde.",
              ),
              voltarAgenda: false,
            });
          }
          setBloquearBotao(false);
          navigate("/missionFinished/" + codigo + "/VIDEO/" + codigoAtividade);
        }
      },
    );
  };

  const timeUpdate = (event) => {
    if (event) {
      if (!finalizado) {
        if (event.percent > 0.8) {
          setBloquearBotao(false);
        }
      }
    }
  };

  const getVideo = () => {
    if (isVimeo(video)) {
      return (
        <VimeoVideo
          video={video}
          onReady={videoCarregado}
          onTimeUpdate={timeUpdate}
          sizeScreen={sizeScreen}
        />
      );
    } else if (video.url.indexOf("videos.weex.digital") !== -1) {
      return <Mp4Video video={video} />;
    } else {
      return <IframeVideo video={video} onLoad={videoCarregado} />;
    }
  };

  const isVideoCarregado = () => {
    return (
      vimeoCarregado ||
      (video && video.url.indexOf("videos.weex.digital") !== -1)
    );
  };

  const onChangeCurtiuVideo = (valor) => {
    setCurtiuVideo(valor);
  };

  const onChangeComentarioVideo = (valor) => {
    setComentarioVideo(valor.target.value);
  };

  const botaoBloqueado = () => {
    if (isVimeo()) {
      return bloquearBotao && isVimeo();
    } else {
      return bloquearBotao;
    }
  };

  const isVimeo = (videoParam) => {
    if (videoParam) {
      return (
        videoParam && videoParam.url && videoParam.url.indexOf("vimeo") !== -1
      );
    }
    return video && video.url && video.url.indexOf("vimeo") !== -1;
  };

  const videoClass = () => {
    if (isDesktopMode) {
      return "column is-offset-2";
    } else {
      return "mobile-video";
    }
  };

  let msgVideoInformacoesGerais = i18n.message(
    "aria.label.video.informacoes.gerais",
    "Para desbloquear o botão de concluir atividade é necessário assistir o vídeo até o final.",
  );

  return (
    <VideoStyled sizeScreen={sizeScreen}>
      <div className="dinamica-content-desktop">
        {isDesktopMode && (
          <div className="desktop-video-voltar">
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message(
                "aria.label.geral.mensagem.voltar.atividades",
                "Volta para a página de atividades.",
              )}
              funcao={() => {
                navigate("/home");
              }}
            />
          </div>
        )}
        {erro != null && (
          <WeexModal
            fecharModalCallback={callbackModal}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        )}
        <div
          className={`${
            isDesktopMode
              ? "dinamica-container columns is-desktop is-centered"
              : ""
          }`}
        >
          <div
            id="informacoes-gerais"
            className="video-desktop-content-left column is-one-third"
            style={!isDesktopMode ? { paddingBottom: "0.75rem" } : {}}
          >
            <DinamicasHeader
              title={video?.title || video?.name}
              dinamicaNome={i18n.message("dinamica.video.nome", "Vídeo")}
              dinamicaAlt={i18n.message(
                "alt.dinamica.video",
                "Ícone de um player de vídeo.",
              )}
              dinamicaImg={
                location && location.state && location.state.icon
                  ? location.state.icon
                  : "/videos.png"
              }
              type="video"
            />
            <span className="weex__a11y--visuallyHidden">
              {msgVideoInformacoesGerais}
            </span>
            {isDesktopMode && (
              <FormVideoFeedback
                isVideoCarregado={isVideoCarregado}
                curtiuVideo={curtiuVideo}
                comentarioVideo={comentarioVideo}
                onChangeCurtiuVideo={onChangeCurtiuVideo}
                onChangeComentarioVideo={onChangeComentarioVideo}
                botaoBloqueado={botaoBloqueado}
                redirecionar={redirecionar}
                classNameDesktopFeedbackDiv="feedback-video"
                classNameDesktopBotaoDiv="video-botao-entendi-desktop"
              />
            )}
          </div>
          {video && video.url && (
            <div id="player-de-video" className="column is-desktop_video">
              <div className="columns is-centered">
                <div className={videoClass()}>{getVideo()}</div>
              </div>
            </div>
          )}
        </div>
        {!isDesktopMode && (
          <FormVideoFeedback
            isVideoCarregado={isVideoCarregado}
            curtiuVideo={curtiuVideo}
            comentarioVideo={comentarioVideo}
            onChangeCurtiuVideo={onChangeCurtiuVideo}
            onChangeComentarioVideo={onChangeComentarioVideo}
            botaoBloqueado={botaoBloqueado}
            redirecionar={redirecionar}
          />
        )}
      </div>
    </VideoStyled>
  );
}

export default Videos;
