import styled from "styled-components";

export const FeedbackStyle = styled.div`
  h1,
  p,
  span,
  label,
  textarea {
    text-align: center;
  }
  .weex-card-header {
    text-align: center;
    display: block;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
  .questao_feedback {
    @media (min-width: 50rem) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-bottom: 1.5em;
    }

    .mensagem-op {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      /* justify-content: center; */
    }
  }

  .questao_feedback_enunciado {
    font-size: 1.188rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #201d1d;
    margin-bottom: 1.5rem;
  }

  .estrelas {
    display: flex;
    justify-content: center;
  }

  .card-header-feedback {
    width: 100%;
  }

  .conteudo-card-atividades {
    margin-top: 3vh;
    min-height: 70vh;
    overflow: hidden;
    padding-bottom: 3vh;
  }

  .card-header-feedback p {
    font-size: 0.5em;
    font-weight: bold;
  }

  .btn-invisible {
    background-color: transparent;
    border: none;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
  }

  textarea,
  .textarea {
    resize: none;
  }

  .container_sim_nao {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .feedback__enviarContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .questao_feedback--comentario {
      width: 50%;
      margin: auto;
    }
    .button-enviar-feedback {
      width: 50%;
    }
    .questao_feedback_enunciado {
      text-align: center;
    }
  }
  /* #endregion DESKTOP */

  @media screen and (min-width: 1024px) {
    .questao_feedback--comentario {
      width: 35%;
      margin: auto;
    }
    .button-enviar-feedback {
      width: 35%;
    }
  }

  @media screen and (min-width: 1300px) {
    .questao_feedback--comentario {
      width: 25%;
      margin: auto;
    }
    .button-enviar-feedback {
      width: 25%;
    }
  }
`;
