import React from "react";
import { useStore } from "react-redux";
import Campaign from "./Campaign";
import { ThemeStyle } from "./themeStyle";
import { CampaignStyle } from "./campaignStyle";
export default function Theme({ themeEvent }) {
  const i18n = useStore().getState().i18n;
  return (
    <ThemeStyle>
      <header className="themeStyle-header">
        {themeEvent.url && themeEvent.url !== "#" ? (
          <div className="themeStyle-header__icon">
            <img
              src={themeEvent.url}
              className="themeStyle__icon"
              alt={i18n.message(
                "alt.virtualCampaign.icone.tema.campanha",
                "Ícone do tema da campanha",
              )}
            />
          </div>
        ) : (
          ""
        )}
        <h5>{themeEvent.name}</h5>
      </header>
      <CampaignStyle>
        {themeEvent.events.map((event) => {
          return (
            <div key={event.uuid}>
              <Campaign event={event} />
            </div>
          );
        })}
      </CampaignStyle>
    </ThemeStyle>
  );
}
