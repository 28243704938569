import React, { useEffect, useState } from "react";
import WeexSelect from "../../WeexInputs/WeexSelect";
import UnidadeService from "../../../services/UnidadeService";
import Spinner from "../../publicacao/Spinner";

/**
 *
 * @param {Object} props
 * @param {import('../../../services/CampanhaService').Event} props.campanha
 * @param {i18n} props.i18n
 * @param {Function} props.onChange
 * @param {('INSCRICAO'|'LOGIN')} props.page Page type - must be either 'INSCRICAO' or 'LOGIN'
 * @returns
 */

function GerarSelectUnidadesTree({ campanha, onChange, i18n, page = "LOGIN" }) {
  const [selectsUnidades, setSelectsUnidades] = useState([]);
  const [unidadeMae, setUnidadeMae] = useState(null);
  const [subareas, setSubareas] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);

  const [loading, setLoading] = useState(false);

  const unidadeService = new UnidadeService();

  const [subUnidadeSelect, setSubUnidadeSelect] = useState(null);

  const [nivel3UnidadeSelect, setNivel3UnidadeSelect] = useState(null);

  useEffect(() => {
    /**
     * @param {import('../../../services/CampanhaService').Unit} unidade
     */
    const filterUnits = (unidade) => {
      if (unidade.subUnits) {
        unidade.subUnits = unidade.subUnits.filter(
          (subUnidade) => subUnidade.register,
        );
        if (unidade.subUnits && unidade.subUnits.length > 0) {
          unidade.subUnits.forEach(filterUnits);
        }
      }
    };

    const inputUnidades = (units) => {
      if (units) {
        let unidades = units;
        if (unidades && unidades.length > 0) {
          if (page === "INSCRICAO") {
            const unidadesFilter = unidades.filter(
              (unidade) => unidade.register,
            );

            // de forma recursiva, filtra as subunidades que possuem subunidades com register true
            unidadesFilter.forEach(filterUnits);
            return unidadesFilter;
          }
          return unidades;
        }
        return null;
      }
    };

    setLoading(true);

    const getUnidades = async () => {
      const unidadesResponse = await unidadeService.getUnidades(
        campanha.client.uuid,
      );
      const unidades = inputUnidades(unidadesResponse.data);
      setSelectsUnidades(unidades);
      setLoading(false);
    };

    if (campanha?.registrationUnitMandatory) {
      getUnidades();
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputUnidade = () => {
    if (selectsUnidades && selectsUnidades.length > 0) {
      return (
        <>
          <WeexSelect
            id="selectUnit"
            value={unidadeMae}
            name="unit"
            onChange={(e) => mudarUnidade(e, 0)}
            label={i18n.message("registro.campo.area", "Selecione sua área")}
            required={false}
          >
            <option value="">
              {i18n.message("select.unidade", "Selecione uma unidade")}
            </option>
            {selectsUnidades.map((opcao) => {
              return (
                <option value={opcao.uuid} key={opcao.uuid}>
                  {opcao.name}
                </option>
              );
            })}
          </WeexSelect>
        </>
      );
    }
    return null;
  };

  const buscarSubUnidades = async (unidadeMaeId, nivel) => {
    setLoading(true);

    if (unidadeMaeId) {
      const unidadeResponse = await unidadeService.getUnidades(
        campanha.client.uuid,
        unidadeMaeId,
      );
      if (unidadeResponse.data) {
        if (nivel === 0) {
          setSubUnidadeSelect(unidadeResponse.data);
        }

        if (nivel === 1) {
          setNivel3UnidadeSelect(unidadeResponse.data);
        }
      }
    }
    setLoading(false);
  };

  const inputSubUnidade = () => {
    //a partir da unidade mae, buscar as subunidades
    //buscar no endpoint as unidades filhas

    if (!subUnidadeSelect || subUnidadeSelect.length === 0) {
      return null;
    }

    return (
      <>
        {subUnidadeSelect && subUnidadeSelect.length > 0 ? (
          <WeexSelect
            id="selectSubUnit"
            value={subareas}
            name="subUnit"
            onChange={(e) => mudarUnidade(e, 1)}
            onBlur={(e) => mudarUnidade(e, 1)}
            label={i18n.message(
              "registro.campo.subarea",
              "Selecione sua subárea",
            )}
          >
            <option value="">
              {i18n.message("select.subárea", "Selecione uma subárea")}
            </option>
            {subUnidadeSelect.map((opcao) => {
              return (
                <option value={opcao.uuid} key={opcao.uuid}>
                  {opcao.name}
                </option>
              );
            })}
          </WeexSelect>
        ) : null}
      </>
    );
  };

  const inputLevel3 = () => {
    if (unidadeMae == null) {
      return null;
    }
    if (subUnidadeSelect == null) {
      return null;
    }

    if (
      nivel3UnidadeSelect == null ||
      nivel3UnidadeSelect?.subUnits?.length === 0
    ) {
      return null;
    }
    console.log("nivel3UnidadeSelect", nivel3UnidadeSelect);
    return (
      <>
        {nivel3UnidadeSelect && nivel3UnidadeSelect.length > 0 ? (
          <WeexSelect
            id="nivel3"
            value={subSubUnit}
            name="level3"
            onChange={(e) => mudarUnidade(e, 2)}
            label={i18n.message(
              "registro.campo.nivel3",
              "Selecione sua sub-subárea",
            )}
          >
            <option value="">
              {i18n.message(
                "select.subsubunidade",
                "Selecione uma sub-subunidade",
              )}
            </option>
            {nivel3UnidadeSelect.map((opcao) => {
              return (
                <option value={opcao.uuid} key={opcao.uuid}>
                  {opcao.name}
                </option>
              );
            })}
          </WeexSelect>
        ) : null}
      </>
    );
  };

  const mudarUnidade = (event, nivel) => {
    event.preventDefault();
    const value = event.target.value;

    if (nivel === 0) {
      //buscar as unidades selecionada no select das unidades mae
      const unidadeMaeSelect = selectsUnidades.find(
        (unidade) => unidade.uuid === value,
      );

      if (unidadeMaeSelect && unidadeMaeSelect.id) {
        buscarSubUnidades(unidadeMaeSelect.id, nivel);
      }
    }

    if (nivel === 1) {
      const subUnidade = subUnidadeSelect?.find(
        (unidade) => unidade.uuid === value,
      );

      if (subUnidade && subUnidade.id) {
        buscarSubUnidades(subUnidade.id, nivel);
      }
    }

    switch (nivel) {
      case 0:
        setUnidadeMae(value);
        setSubareas(null);
        setSubSubUnit(null);
        onChange(event);
        break;
      case 1:
        setSubareas(value);

        setSubSubUnit(null);
        onChange(event);
        break;
      case 2:
        setSubSubUnit(value);
        onChange(event);
        break;
      default:
        console.error("Nível desconhecido");
        break;
    }
  };

  return (
    <>
      {loading ? (
        <Spinner isVisivel={loading} />
      ) : (
        <>
          <fieldset className="registerForm__fieldset">
            {inputUnidade()}
            {inputSubUnidade()}
          </fieldset>
          {inputLevel3()}
        </>
      )}
    </>
  );
}

export default GerarSelectUnidadesTree;
