import React, { useEffect, useState, useCallback } from "react";
import { useStore } from "react-redux";
import { RoletaGanhouCardStyle } from "../style";
import ModalRoletaComprovanteHistorico from "../../weexModais/ModalRoletaComprovanteHistorico";
import RoletaTicketsService from "../../../services/RoletaTicketsService";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
export function BrindeRoleta({
  title,
  subtitle,
  brindeUuid,
  dataSorteio,
  unidade,
  nomeParticipante,
  matricula,
  uuid,
  brindeName,
  brindeDescription,
  donwloadComprovante,
  audioRef,
}) {
  const i18n = useStore().getState().i18n;
  const [ativaModalComprovante, setAtivaModalComprovante] = useState(false);
  const [img, setBrindeImg] = useState(null);

  const irParaBaixaComprovante = useCallback((ticket) => {
    setAtivaModalComprovante(true);
  }, []);

  useEffect(() => {
    audioRef.current.play();
    audioRef.current.volume = 0.3; // Volume reduzido (30%)
  }, [audioRef]);

  useEffect(() => {
    const roletaTicketsService = new RoletaTicketsService();
    const fetchImagemBrind = async () => {
      try {
        const response = await roletaTicketsService.getFile(
          "GIVEAWAY",
          "file",
          brindeUuid,
        );

        const url = URL.createObjectURL(response.data);
        setBrindeImg(url);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchImagemBrind();
  }, [brindeUuid]);

  const download = () => {
    let i18nMessage = i18n.message(
      "roleta.btn.go.comprovante",
      "Baixar comprovante",
    );
    let i18nAriaStatus = i18n.message(
      "aria.status.roleta.btn.go.comprovante",
      "Botão Baixar comprovante clicado.",
    );
    return (
      <>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          {/*<BotaoRoleta*/}
          {/*  nome={i18nMessage}*/}
          {/*  funcao={irParaBaixaComprovante}*/}
          {/*  ariaStatusMessage={i18nAriaStatus}*/}
          {/*  disabledOnClick={false}*/}
          {/*/>*/}

          <PrimarioBtn
            nome={i18nMessage}
            disabled={false}
            funcao={irParaBaixaComprovante}
            ariaStatusMessage={i18nAriaStatus}
          />
        </section>
      </>
    );
  };
  return (
    <>
      {ativaModalComprovante ? (
        <ModalRoletaComprovanteHistorico
          brindeImg={uuid}
          brindeNome={brindeName}
          nome={nomeParticipante}
          matricula={matricula}
          unidade={unidade}
          dataSorteio={dataSorteio}
          fecharModal={() => setAtivaModalComprovante(false)}
          brindeUuid={brindeUuid}
          ticketUuid={uuid}
        ></ModalRoletaComprovanteHistorico>
      ) : (
        ""
      )}
      <main className="weexDialog__main">
        <section>
          {title && (
            <h1 className="weexDialog__texts--alignment weexDialog__title">
              {title}
            </h1>
          )}
          {subtitle && (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              <span className="weexDialog__texts--alignment weexDialog__texts">
                {subtitle}
              </span>
            </div>
          )}
        </section>
        <RoletaGanhouCardStyle>
          {img ? (
            <div className="roleta-image-container roleta-image-container--premio">
              <img
                src={img}
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          ) : (
            <div className="roleta-image-container roleta-image-container--icone">
              <img
                src="/svgs-3.0/gift.png"
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          )}
          {brindeName && <h3 className="roleta-texto">{brindeName}</h3>}
          {brindeDescription && (
            <p
              className="roleta-texto roleta-texto-descricao"
              dangerouslySetInnerHTML={{ __html: brindeDescription }}
            />
          )}
        </RoletaGanhouCardStyle>
        {donwloadComprovante && download()}
      </main>
    </>
  );
}
