import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RankingGameService from "../../services/RankingGameService";
import AtividadeEntity from "../agenda/AtividadeEntity";
import WeexSelect from "../WeexInputs/WeexSelect";

function GameRankings({ searchRankingGame }) {
  const dispatch = useDispatch();
  const i18n = useSelector((state) => state.i18n);
  const rankingService = new RankingGameService();
  const gameUuid = useSelector((state) => state.gameUuid);
  const [games, setGames] = useState([]);

  useEffect(() => {
    setGames(rankingService.getGamesAvailable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameItems = () => {
    return games.map((g) => {
      const game = new AtividadeEntity(g);
      const dia = game?.day?.split("-")[2];
      return (
        <option selected={isSelected(game)} key={game.uuid} value={game.uuid}>
          {`GAME DIA ${dia}  ${game.title ? game.title : game.name}`}
        </option>
      );
    });
  };

  const isSelected = (g) => {
    const game = new AtividadeEntity(g);
    return gameUuid === game.uuid;
  };

  const gameSelected = (event) => {
    const newGameUuid = event.target.value;
    // Despacha a ação para atualizar o valor no Redux
    dispatch({ type: "game", payload: newGameUuid });

    const selectedGame = games.find((game) => game.uuid === newGameUuid);
    if (selectedGame) {
      searchRankingGame(selectedGame);
    }
  };
  return (
    <form>
      <WeexSelect
        label={i18n.message("selecione.game", "Selecione o game")}
        value={gameUuid}
        onChange={gameSelected}
        onBlur={gameSelected}
        style={{ height: "3.238rem" }}
      >
        {gameItems()}
      </WeexSelect>
    </form>
  );
}

export default GameRankings;
