import styled from "styled-components";

export const RoletaHistoricoStyle = styled.div`
  .ticketItems {
    margin-top: 1rem;
  }
  .weex-l-cabecalho {
    margin-top: 0 !important;
  }
  .ticket {
    margin-bottom: 1rem;
    margin-top: 1rem;
    background-color: rgba(227, 228, 227, 0.25);
    border-radius: 1.25rem;
  }
  .ticketHeader__alignedSubelements {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
  }

  .ticketHeader {
    font-weight: 500;
  }
  .ticketBtn {
    margin: auto;
    width: 60%;
  }
  .ticketBody__PONTOS {
    display: flex;
    color: #005925;
    font-weight: 600;
    font-size: 2rem;
    background: white;
    padding: 15px;
    border-radius: 10px;
  }
  .ticketBody__PONTOS--pontos {
    margin-left: 0.25rem;
    color: #005925;
  }

  .ticketBtn button {
    padding: 0.5rem 1rem;
    letter-spacing: 1px;
  }

  .ticketBtn span {
    font-size: 12px;
  }

  .ticketBtn .content-botao {
    padding: 0;
  }

  .ticketBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    background-color: rgba(144, 144, 144, 0.1);
    border-radius: 1.25rem;
  }

  .ticketBody__brindeNome {
    font-size: 1.25rem;
    font-weight: 500;
    color: black;
    margin-bottom: 2rem;
  }
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .ticketItems {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .ticketBody {
      height: 8rem;
    }

    .ticket {
      width: 30%;
    }
  }
`;
