import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import { MenuStyle } from "./style";
import BotaoTerciario from "../../button/BotaoTerciario";
import Trophy from "../../svg/botoes/Trophy";
import InviteFriends from "../../svg/botoes/InviteFriends";
import Medalha from "../../svg/Medalha";

function SubmenuMobile() {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  let location = useLocation();
  const campanhaComecou = useSelector((state) => state.campanhaComecou);

  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const locationPathname = location?.pathname;
  const isVirtualCampaignPage =
    campanhaService.isRecurrentCampaign() &&
    locationPathname === "/campaign/virtual";
  let enableInvite;
  let campaignCode;
  let campaignType;
  if (campanhaCorrente) {
    campaignCode = campanhaCorrente?.code;
    enableInvite = campanhaCorrente?.enableInvite;
    campaignType = campanhaCorrente?.campaignType;

    const ocultarRanking =
      campanhaCorrente.personalizationEvent?.HIDE_RANKING === "true";
    if (ocultarRanking) {
      campaignType = "";
    }
  }

  const conquistas = () => {
    const uri = campanhaComecou ? "/trophy" : "/";
    navigate(uri);
  };

  const inviteFriends = () => {
    navigate(`/invite/${campaignCode}`);
  };

  const gotToRanking = () => {
    if (campaignType === "INDIVIDUAL") {
      navigate(`/campanhaRanking`);
    }
    if (campaignType === "EQUIPE") {
      navigate(`/campanhaRankingPorEquipe`);
    }
  };

  const invite = () => {
    if (enableInvite && !isVirtualCampaignPage) {
      return (
        <li>
          <BotaoTerciario
            size="small"
            funcao={inviteFriends}
            nome={i18n.message("geral.menu.chamarAmigos", "Chamar amigos")}
            ariaLabelMsg={i18n.message(
              "alt.convite.afiliados.icone",
              "Ícone silhueta de usuário com simbola de +",
            )}
            disabledOnClick={false}
            iconposition="left"
            iconLeft={
              <>
                <InviteFriends />
              </>
            }
          />
        </li>
      );
    }
  };

  const ranking = () => {
    if (
      (campaignType === "INDIVIDUAL" || campaignType === "EQUIPE") &&
      !isVirtualCampaignPage
    ) {
      return (
        <li>
          <BotaoTerciario
            size="small"
            funcao={gotToRanking}
            nome={i18n.message("geral.menu.campanhaRanking", "Ranking")}
            ariaLabelMsg={i18n.message(
              "alt.rankings.icone",
              "Ícone de medalha com acesso para a página de ranking",
            )}
            disabledOnClick={false}
            disabled={!campanhaComecou}
            iconposition="left"
            iconLeft={
              <>
                <Medalha />
              </>
            }
          />
        </li>
      );
    }
  };

  const conquer = () => {
    if (!isVirtualCampaignPage) {
      return (
        <li>
          <BotaoTerciario
            size="small"
            funcao={conquistas}
            nome={i18n.message("geral.menu.conquistas", "Conquistas")}
            disabledOnClick={false}
            disabled={campanhaComecou ? false : true}
            ariaLabelMsg={i18n.message(
              "alt.menu.sala.de.trofeus",
              "Ícone com acesso para a sala de conquistas",
            )}
            iconposition="left"
            iconLeft={
              <>
                <Trophy />
              </>
            }
          />
        </li>
      );
    }
  };

  return (
    <MenuStyle>
      <ul className="submenu__group--mobile menuStyle__group menuStyle__group--btn">
        {ranking()}
        {conquer()}
        {invite()}
      </ul>
    </MenuStyle>
  );
}

export default SubmenuMobile;
