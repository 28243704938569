import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login2FA from "../../../comps/authInscricao/login/Login2FA";
import LoginCampaign from "../../../comps/authInscricao/login/LoginCampaign";
import WeexSpinner from "../../../comps/WeexSpinner/WeexSpinner";
import CampanhaService from "../../../services/CampanhaService";
import TemaService from "../../../services/TemaService";
import UsuarioService from "../../../services/UsuarioService";
import RegisterSelector from "../inscricao/RegisterSelector";
import { LoginRegisterStyle } from "../style";
import UnidadeService from "../../../services/UnidadeService";

function LoginRegister() {
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();

  const campanhaService = useMemo(() => new CampanhaService(), []);
  const usuarioService = new UsuarioService();
  const usuario = usuarioService?.usuarioCorrente();
  const navigate = useNavigate();
  const campanhaCorrente = campanhaService?.campanhaCorrente();
  const adminOrGestor = usuarioService.isAdmin() || usuarioService.isGestor();

  const tipoInscricao = campanhaCorrente?.tipoInscricao;
  const elegibilidade = campanhaCorrente?.restrictEligibility;
  const campaignType = campanhaCorrente?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";

  const [jaExecutouUnidade, setJaExecutouUnidade] = useState(false);

  const [modo, setModo] = useState("notfound");
  // const [modo, setModo] = useState(
  //   campanhaService.campanhaCorrente() ? campanhaService.campanhaCorrente().restrictEligibility ? "login" : "register" : "notfound",
  // );

  useEffect(() => {
    const temaService = new TemaService(campanhaCorrente);
    if (temaService.temBackGround()) {
      dispatch({ type: "backgroundispresent", payload: "true" });
      temaService.aplicarTema("ENTRADA");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUnidades = async () => {
      const unidadeService = new UnidadeService();
      const unidadesResponse = await unidadeService.getUnidades(
        campanhaCorrente.client.uuid,
      );

      //sobrescreve as unidades no local storage

      campanhaCorrente.client.units = unidadesResponse.data;

      campanhaService.setCampanhaLocalStorage(campanhaCorrente);
    };

    if (campanhaCorrente) {
      if (
        campanhaCorrente.client.units == null ||
        campanhaCorrente?.client?.units?.length === 0 ||
        !jaExecutouUnidade
      ) {
        setJaExecutouUnidade(true);
        getUnidades();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usuario && campanhaCorrente) {
      campanhaService.contadorCampanha((erro, sucesso) => {
        if (erro) {
          console.error(erro);
          return;
        }
        if (sucesso) {
          if (sucesso.length > 0 || adminOrGestor) {
            dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
            dispatch({ type: "campanhaComecou", payload: false });
            if (
              elegibilidade &&
              tipoInscricao === "ELEGIBILIDADE" &&
              isCampaignTypeEquipe &&
              !usuario?.team &&
              !adminOrGestor
            ) {
              navigate("/selectTeam");
              return;
            }
            navigate("/home");
          }
        }
      });
    } else {
      const temaService = new TemaService(campanhaCorrente);
      temaService.aplicarTema("ENTRADA");
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!campanhaCorrente) {
      navigate("/campaign");
    }
  }, [campanhaCorrente, navigate]);

  const modoLogin = () => {
    dispatch({ type: "modo", payload: "login" });
    setModo("login");
  };

  const modoRegister = () => {
    dispatch({ type: "modo", payload: "register" });
    setModo("register");
  };

  const login = () => {
    if (campanhaCorrente?.extraAuthInfo?.includes("EMAIL_2FA")) {
      return <Login2FA />;
    } else if (campanhaCorrente) {
      return (
        <LoginCampaign
          campanha={campanhaCorrente}
          modoRegister={modoRegister}
        />
      );
    }
  };

  return (
    <LoginRegisterStyle>
      {usuario ? (
        <WeexSpinner aguarde={i18n.message("geral.aguarde", "Aguarde...")} />
      ) : (
        <div className="LoginRegister__container">
          <div style={{ width: "100%" }}>
            {modo === "register" ? (
              <RegisterSelector modoLogin={modoLogin} />
            ) : (
              <>{login()}</>
            )}
          </div>
        </div>
      )}
    </LoginRegisterStyle>
  );
}
export default LoginRegister;
