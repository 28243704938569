// TODO: Refatorar para separar as regras em serviços
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useStore, useSelector } from "react-redux";
import CampanhaService from "../../services/CampanhaService";
import { PontuacaoRoleta } from "./resultado/PontuacaoRoleta";
import { BrindeRoleta } from "./resultado/BrindeRoleta";
import { VoucherRoleta } from "./resultado/VoucherRoleta";
import BotaoFechar from "../BotaoFechar/BotaoFechar";
import { Roleta } from "./Roleta";
import { RoletaModalStyle } from "./style";
import RoletaTicketsService from "../../services/RoletaTicketsService";
import UsuarioService from "../../services/UsuarioService";
import WeexModal from "../weexModais/WeexModal";
import NotificadorService from "../../services/NotificadorService";
import { AnimatedComponent } from "./roletaStyle";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import TrofeuService from "../../services/TrofeuService";
const campanhaService = new CampanhaService();
const roletaTicketsService = new RoletaTicketsService();

export function RoletaModal() {
  const comemoracaoRef = useRef(new Audio("/sound/congratulations.mp3"));
  const roletaAudioRef = useRef(new Audio("/sound/roleta.mp3"));
  const [targetReward, setTargetReward] = useState(null);
  const [isRotating, setIsRotating] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const scheduleCode = useSelector((state) => state.scheduleCode);
  const tipoRoleta = useSelector((state) => state.tipoRoleta);
  const i18n = useStore().getState().i18n;
  const ticketsSorteados = useSelector((state) => state.ticketsSorteados);
  const dialogRef = useRef(null);
  const campanha = campanhaService.campanhaCorrente();
  const [step, setStep] = useState("LOADING");
  const dispatch = useDispatch();
  const usuario = new UsuarioService().usuarioCorrente();
  const notificadorService = new NotificadorService();
  const [jaSorteado, setJaSorteado] = useState(true);
  const usuarioCorrente = new UsuarioService().usuarioCorrente();
  const [trofeuCampanha, setTrofeuCampanha] = useState(null);

  const tempoEsperaAtePooling =
    process.env.REACT_APP_HOST_TEMPO_ESPERA_ATE_POOLING || 2000;
  const retentativaPooling = process.env.REACT_APP_RETENTATIVA_POOLING || 8;
  const tempoEsperaEntreTentativas =
    process.env.REACT_APP_HOST_TEMPO_ESPERA_ENTRE_TENTATIVAS || 1500;

  const [ticket, setTicket] = useState({});
  const [erro, setErro] = useState(null);
  const [roleta, setRoleta] = useState(null);
  //verificar se o codigo da agenda é diferente de null

  useEffect(() => {
    if (roleta) {
      setIsRotating(null);
      setTargetReward(null);
      let matchedTicket;
      if (tipoRoleta === "CAMPAIGN_ROULETTE") {
        matchedTicket = ticketsSorteados.find(
          (ticket) =>
            ticket.scheduleUuid === null || ticket.scheduleUuid === undefined,
        );
      } else {
        matchedTicket = ticketsSorteados.find(
          (ticket) => ticket.scheduleUuid === scheduleCode,
        );
      }

      if (matchedTicket) {
        setJaSorteado(true);
        const ticketFormat = ticketFormatado(matchedTicket);
        setTicket(ticketFormat);
        setStep("SPIN");
        switch (matchedTicket.giveawayType) {
          case "BRINDE":
            setStep("BRINDE");
            break;
          case "VOUCHER":
            setStep("VOUCHER");
            break;
          default:
            setStep("PONTOS");
        }
      } else {
        setJaSorteado(false);
        setStep("SPIN");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleta]);

  useEffect(() => {
    setIsRotating(null);
    setTargetReward(null);
    //se houver valor  salvar no localstorage
    if (tipoRoleta === "CAMPAIGN_ROULETTE") {
      const roleta = campanhaService.getRoleta(null, tipoRoleta);
      setRoleta(roleta);
      dispatch({ type: "roletaCustomizacao", payload: roleta });
    } else {
      if (scheduleCode) {
        localStorage.setItem("scheduleCode", scheduleCode);
        //atualizar as cores do tema
        dispatch({ type: "scheduleCode", payload: scheduleCode });

        //atualizar a roleta
        const roleta = campanhaService.getRoleta(scheduleCode, tipoRoleta);
        setRoleta(roleta);
        dispatch({ type: "roletaCustomizacao", payload: roleta });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleCode, tipoRoleta]);

  useEffect(() => {
    if (tipoRoleta === "CAMPAIGN_ROULETTE" && !jaSorteado) {
      const fetchTrofeus = async () => {
        const trofeuService = new TrofeuService();

        const params = {
          participantUuid: usuario?.participantCode,
          trophyType: "CAMPANHA",
        };

        const trofeusResponse = await trofeuService.getTtrofeus(
          usuario?.participantCode,
          params,
        );
        if (trofeusResponse?.data) {
          //deve retornar um array com apenas um elemento
          setTrofeuCampanha(trofeusResponse.data[0]);
        }
      };

      if (usuario?.participantCode) {
        fetchTrofeus();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jaSorteado]);

  function onCancel(e) {
    e.preventDefault();
    const { current } = dialogRef;
    current.close();
    ocultar();
  }

  const openDialog = useCallback(() => {
    const { current } = dialogRef;
    current.close();
    current.showModal();
  }, [dialogRef]);

  useEffect(() => {
    openDialog();
  }, [openDialog]);

  const ticketFormatado = (ticket) => {
    //deep copy
    const ticketFormatado = JSON.parse(JSON.stringify(ticket));
    ticketFormatado.unidadeFormatted = formataUnidade(
      ticketFormatado.unit,
      ticketFormatado?.subUnit,
      ticketFormatado?.level3,
    );
    // eslint-disable-next-line max-len
    ticketFormatado.dataSorteioFormatted = formatarData(
      new Date(ticketFormatado.date),
    );
    return ticketFormatado;
  };

  /* eslint-disable no-unused-vars */
  const pulling = () => {
    //todo função real de pooling
    let delayTimeoutId;
    let intervalId;

    let tentativas = 0; // Inicializar contador de tentativas

    // Função que realiza o pooling
    const iniciarPooling = () => {
      const verificarSorteio = () => {
        tentativas += 1; // Incrementar número de tentativas

        // Se já sorteado, interrompe o pooling
        if (jaSorteado) {
          clearInterval(intervalId);
          clearTimeout(delayTimeoutId);
          return;
        }

        // Se o número de tentativas for maior ou igual ao limite, para o pooling
        if (tentativas >= retentativaPooling) {
          resetarAudio();
          setIsRotating(false);
          clearInterval(intervalId);
          clearTimeout(delayTimeoutId);
          console.error("Número de tentativas excedido. Finalizando pooling.");
          setErro({
            titulo: i18n.message(
              "roleta.erro.obter.sorteio",
              "Erro ao obter sorteio",
            ),
            detalhe: i18n.message(
              "roleta.erro.obter.sorteio.detalhe",
              "Número de tentativas excedido",
            ),
          });
          return;
        }

        // Lógica de busca de notificação
        notificadorService
          .buscarMensagensParticipante({
            tag:
              tipoRoleta === "CAMPAIGN_ROULETTE"
                ? trofeuCampanha?.uuid
                : scheduleCode,
            userId: usuarioCorrente?.participantCode,
          })
          .then((response) => {
            const notificacao = response?.data?.data?.[0];

            if (!notificacao) {
              throw new Error("Notificação não encontrada");
            }

            const ticketParam = notificacao.parameters.find(
              (param) => param.code === "ticket",
            );
            const isSucessParam = notificacao.parameters.find(
              (param) => param.code === "isSucess",
            );

            if (
              ticketParam &&
              isSucessParam &&
              roleta &&
              usuarioCorrente?.participantCode
            ) {
              const roletaTicketService = new RoletaTicketsService();
              dispatch({ type: "ticketsChanger", payload: true }); // todo recarregar a pagina
              return roletaTicketService.obterTickets(roleta.uuid, null);
            }
          })
          .then((ticketsResponse) => {
            if (ticketsResponse?.data?.tickets) {
              let tiketTemp = null;

              if (tipoRoleta === "CAMPAIGN_ROULETTE") {
                tiketTemp = ticketsResponse.data.tickets.find(
                  (ticketsResponse) =>
                    ticketsResponse.trophyUuid === trofeuCampanha?.uuid,
                );
              } else {
                tiketTemp = ticketsResponse.data.tickets.find(
                  (ticketsResponse) =>
                    ticketsResponse.scheduleUuid === scheduleCode,
                );
              }
              const firstTicket = ticketFormatado(tiketTemp);
              if (firstTicket) {
                clearInterval(intervalId);
                clearTimeout(delayTimeoutId);
                setTicket(firstTicket);
                setJaSorteado(true);
                setIsRotating(false);
                setTargetReward(firstTicket.giveawayUuid);
              }
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar notificação:", error);
          });
      };

      // Iniciar o intervalo para verificar sorteio
      intervalId = setInterval(verificarSorteio, tempoEsperaEntreTentativas);
    };

    // Aguardar antes de iniciar o pooling
    delayTimeoutId = setTimeout(() => {
      iniciarPooling();
    }, tempoEsperaAtePooling);

    // Retornar função para limpar intervalos/tempos pendentes
    return () => {
      if (delayTimeoutId) {
        clearTimeout(delayTimeoutId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  };

  const resetarAudio = () => {
    if (!comemoracaoRef.current.paused) {
      comemoracaoRef.current.pause(); // Para o áudio se ele estiver tocando
      comemoracaoRef.current.currentTime = 0; // Reseta o áudio para o início
    }
    if (!roletaAudioRef.current.paused) {
      roletaAudioRef.current.pause(); // Para o áudio se ele estiver tocando
      roletaAudioRef.current.currentTime = 0; // Reseta o áudio para o início
    }
  };

  const ocultar = () => {
    if (isRotating) {
      return;
    }
    resetarAudio();
    dispatch({ type: "HIDE_MODAL", payload: "roulette" });
    dispatch({ type: "scheduleCode", payload: null });
    dispatch({ type: "tipoRoleta", payload: null });
    dispatch({
      type: "mostrarRoletaAtividade",
      payload: false,
    });
  };

  const onSpin = () => {
    setIsRotating(true);
    setButtonDisabled(true);
    roletaTicketsService
      .spinRoulette(scheduleCode, tipoRoleta)
      .then(() => {
        salvaLocalStorageSorteio(scheduleCode);
      })
      .catch((error) => {
        resetarAudio();
      });
  };

  const spinningRoullete = () => {
    onSpin();
    pulling();
  };

  const formatarData = (data) => {
    if (data) {
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0"); // Os meses começam do zero
      const ano = data.getFullYear();
      const horas = String(data.getHours()).padStart(2, "0");
      const minutos = String(data.getMinutes()).padStart(2, "0");
      return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
    }

    return "";
  };

  const salvaLocalStorageSorteio = (uuidAgenda) => {
    const chaveSorteio = `sorteio-${uuidAgenda}-${usuario?.participantCode}`;
    localStorage.setItem(chaveSorteio, Date.now());
  };

  const formataUnidade = (unidade, subunidade, nivel3) => {
    if (nivel3) {
      return `${unidade} / ${subunidade} / ${nivel3}`;
    } else if (subunidade) {
      return `${unidade} / ${subunidade}`;
    } else if (unidade) {
      return unidade;
    }
    return "";
  };

  const callbackWarning = () => {
    setErro(null);
    dispatch({ type: "HIDE_MODAL", payload: "roulette" });
    dispatch({ type: "ticketsChanger", payload: true });
  };

  const stopRoleta = () => {
    setTimeout(() => {
      if (ticket) {
        switch (ticket.giveawayType) {
          case "BRINDE":
            setStep("BRINDE");
            break;
          case "VOUCHER":
            setStep("VOUCHER");
            break;
          default:
            setStep("PONTOS");
        }
      }
    }, 1000);
  };

  const presentation = () => {
    return (
      <>
        <main className="weexDialog__main">
          <section>
            <h1
              className="weexDialog__texts--alignment weexDialog__title"
              dangerouslySetInnerHTML={{
                __html: roleta?.customization?.title,
              }}
            />
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <span
                className="weexDialog__texts--alignment weexDialog__subtitle"
                dangerouslySetInnerHTML={{
                  __html: roleta?.customization?.subtitle,
                }}
              />
            </div>
          </section>
          {
            <Roleta
              isRotating={isRotating}
              customization={roleta?.customization}
              slices={roleta?.slices}
              targetReward={targetReward}
              audioRef={roletaAudioRef}
              stopRoleta={stopRoleta}
            />
          }
          {buttons()}
        </main>
      </>
    );
  };

  const steps = () => {
    switch (step) {
      case "LOADING":
        return (
          <main className="weexDialog__main">
            <section>
              <h1 className="weexDialog__texts--alignment weexDialog__title">
                {i18n.message("roleta.carregando", "Carregando...")}
              </h1>
            </section>
          </main>
        );
      case "PONTOS":
        return (
          <AnimatedComponent>
            <PontuacaoRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              uuid={ticket?.uuid}
              pointsEarned={ticket?.giveawayProperties?.value}
              brindeUuid={ticket.giveawayUuid}
              competitiveType={campanha?.campaignType}
              ocultar={ocultar}
              audioRef={comemoracaoRef}
            />
          </AnimatedComponent>
        );
      case "BRINDE":
        return (
          <AnimatedComponent>
            <BrindeRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              brindeUuid={ticket.giveawayUuid}
              dataSorteio={ticket.dataSorteioFormatted}
              unidade={ticket.unidadeFormatted}
              nomeParticipante={ticket?.participantName}
              matricula={ticket.enrollment}
              uuid={ticket?.uuid}
              brindeName={ticket?.giveawayName}
              brindeDescription={ticket?.giveawayDescription}
              donwloadComprovante={true}
              audioRef={comemoracaoRef}
            />
          </AnimatedComponent>
        );
      case "VOUCHER":
        return (
          <AnimatedComponent>
            <VoucherRoleta
              title={i18n.message(
                "roleta.premio.titulo",
                "Você está com sorte!",
              )}
              uuid={ticket?.uuid}
              brindeUuid={ticket.giveawayUuid}
              voucherName={ticket?.giveawayName}
              voucherDescription={ticket?.giveawayDescription}
              linkVoucher={ticket?.voucherCode}
              firstTime={!ticket?.read}
              ocultar={ocultar}
              audioRef={comemoracaoRef}
              voucherUuid={ticket?.voucherUuid}
            />
          </AnimatedComponent>
        );
      case "SPIN":
      default:
        return presentation();
    }
  };

  const buttons = () => {
    let i18nMessage = i18n.message("roleta.btn.girar", "Girar");
    let i18nAriaStatus = i18n.message(
      "aria.status.roleta.btn.girar",
      "Botão girar clicado.",
    );
    return (
      <>
        {erro != null ? (
          <WeexModal
            fecharModalCallback={callbackWarning}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        ) : null}
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          {/*<BotaoRoleta*/}
          {/*  nome={i18nMessage}*/}
          {/*  funcao={spinningRoullete}*/}
          {/*  ariaStatusMessage={i18nAriaStatus}*/}
          {/*  disabledOnClick={isRotating}*/}
          {/*/>*/}
          <PrimarioBtn
            nome={i18nMessage}
            disabled={buttonDisabled}
            funcao={spinningRoullete}
            ariaStatusMessage={i18nAriaStatus}
          />
        </section>
      </>
    );
  };

  return (
    <>
      <RoletaModalStyle ref={dialogRef} onCancel={onCancel}>
        <BotaoFechar funcao={ocultar} />
        {steps()}
      </RoletaModalStyle>
    </>
  );
}
