/* eslint-disable react-hooks/exhaustive-deps */
import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import TrofeuService from "../../services/TrofeuService";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { MissaoCumpridaFeedbackModalStyle } from "./StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "./WeexModalDialog";
import { removerPorPrefixo } from "../../services/LocalStorageService";

function ModalConclusaoDia() {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const [processando, setProcessando] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(true);
  const dialogRef = useRef(null);
  const agendaService = new AgendaService();
  const [game, setGame] = useState(null);
  const campanhaService = new CampanhaService();
  const [roleta, setRoleta] = useState(null);
  const trofeuInfo = useSelector((state) => state.trofeuNaoLido);
  const dispatch = useDispatch();
  const scheduleCode = useSelector((state) => state.scheduleCode);
  const tipoRoleta = useSelector((state) => state.tipoRoleta);

  useEffect(() => {
    if (trofeuInfo) {
      if (!processando) {
        setProcessando(true);
        const marcarTrofeuLido = () => {
          const trofeuService = new TrofeuService();
          trofeuService.marcarTrofeuLido(trofeuInfo.uuid);
        };
        lottie.loadAnimation({
          container: document.getElementById(
            "missao-cumprida-container-confetes",
          ),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "/confete.json",
        });
        setGame(agendaService.buscarGameAgenda(trofeuInfo.scheduleUuid));
        marcarTrofeuLido();
        setRoleta(campanhaService.getRoleta(scheduleCode, tipoRoleta));
        //quando abrir o modal remover os achievements
        // que estão no localstorage
        removerPorPrefixo("achievements");
      }
    }
  }, [trofeuInfo, processando]);

  function voltar() {
    dialogRef.current.close();
    setMostrarModal(false);
    dispatch({ type: "HIDE_MODAL", payload: "trofeuNaoLido" });
    navigate("/atividades");
  }

  const irParaGame = () => {
    dispatch({ type: "HIDE_MODAL", payload: "trofeuNaoLido" });
    navigate(`/game/${trofeuInfo.scheduleUuid}/${game.uuid}`);
  };

  const irParaRoleta = (type) => {
    setMostrarModal(false);
    dispatch({ type: "HIDE_MODAL", payload: "trofeuNaoLido" });
    dispatch({
      type: "mostrarRoletaAtividade",
      payload: true,
    });
  };

  return mostrarModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={voltar}>
      <div
        className="modal is-active animations-popIn"
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div style={{ padding: "20px", paddingBottom: "50px" }}>
          <header className="modal-card-head weex-modal-card-header">
            <button
              className="close-btn"
              style={{ zIndex: "1003" }}
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
              onClick={voltar}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <MissaoCumpridaFeedbackModalStyle>
            <div
              id="missao-cumprida-container-confetes"
              className="missao-cumprida-container-confetes"
            ></div>
            <div>
              <div className="modal-card-title wex-modal-card-img">
                <div>
                  <img
                    src="/new_obrigado.svg"
                    alt={i18n.message(
                      "alt.feedback.imagem.obrigado",
                      "Imagem de fogos de artificio agradecendo por ter respondido ao feedback",
                    )}
                    className="weex__image is-2by1"
                  ></img>
                </div>
              </div>
              <h1 className="missao-cumprida__title">
                {i18n.message("missaoCumprida.fim", "PARABÉNS!")}
              </h1>
              <h2 className="missao-cumprida__subtitle">
                {trofeuInfo?.trophyType === "DIA"
                  ? i18n.message(
                      "missaoCumprida.dia.parabens",
                      "Conquista do Troféu do Dia",
                    )
                  : i18n.message(
                      "missaoCumprida.campanha.parabens",
                      "Conquista do Troféu da Campanha",
                    )}
              </h2>
            </div>

            {trofeuInfo?.score > 0 && (
              <div className="pontuacao-container">
                <div className="pontuacao">
                  <span>
                    <h1>
                      {" "}
                      + {trofeuInfo?.score}{" "}
                      {trofeuInfo?.score === 1
                        ? i18n.message("geral.pt", "PT")
                        : i18n.message("geral.pts", "PTS")}
                    </h1>
                  </span>
                </div>
                <br></br>
              </div>
            )}
            {(roleta && trofeuInfo?.trophyType === "DIA") ||
            (roleta &&
              roleta?.campaignTrophy &&
              trofeuInfo?.trophyType === "CAMPANHA") ? (
              <div style={{ marginTop: "35px", marginBottom: "2rem" }}>
                <PrimarioBtn
                  nome={i18n.message(
                    "modal.conclusaoTrofeuDia.roleta",
                    "IR PARA ROLETA",
                  )}
                  ariaLabelMsg={i18n.message(
                    "aria.label.modal.conclusaoTrofeuDia.roleta",
                    "Ir para a roleta.",
                  )}
                  funcao={() => irParaRoleta(trofeuInfo?.trophyType)}
                  classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
                  ariaStatusMessage={i18n.message(
                    "aria.status.modal.conclusaoTrofeuDia.roleta",
                    "Botão voltar clicado Ir para a roleta.",
                  )}
                />
              </div>
            ) : (
              ""
            )}
            {game ? (
              <div style={{ marginTop: "35px", marginBottom: "2rem" }}>
                <PrimarioBtn
                  nome={i18n.message(
                    "modal.conclusaoTrofeuDia.game",
                    "Jogar Game",
                  )}
                  ariaLabelMsg={i18n.message(
                    "aria.label.geral.mensagem.voltar.atividades",
                    "Volta para a página de atividades.",
                  )}
                  funcao={irParaGame}
                  classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
                  ariaStatusMessage={i18n.message(
                    "aria.status.mensagemreutilizavel.botaovoltarclicado",
                    "Botão voltar clicado.",
                  )}
                />
              </div>
            ) : (
              ""
            )}
          </MissaoCumpridaFeedbackModalStyle>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default ModalConclusaoDia;
