import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { CardStyle } from "./style";
import BotaoEntrarTeam from "./BotaoEntrarTeam";
import TeamLogoListagem from "./LogoEquipeListagem";

function SelectTeamCard({
  uuid,
  name,
  leader,
  membersCount,
  score,
  getInTeam,
  url,
}) {
  const i18n = useStore().getState().i18n;

  useEffect(() => {
    // return () => URL.revokeObjectURL(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showScore = () => {
    if (score) {
      return (
        <div className="cardTeam__pointsContainer">
          <span className="cardTeam__pointsContainer--text">
            <b>{score}</b> {i18n.message("geral.pts", "pts")}
          </span>
        </div>
      );
    }
  };
  return (
    <CardStyle>
      <header className="cardTeam__header">
        <div className="cardTeam__imageContainer">
          <TeamLogoListagem uuid={uuid} name={name} url={url} />
        </div>
        <h3>{name}</h3>
        {showScore()}
      </header>
      <main className="cardTeam__main">
        <div className="cardTeam__imgLeaderContainer">
          <img
            alt={i18n.message(
              "aria.cardTeam.icone.lider",
              "ícone com silhueta de um usuario",
            )}
            src="/user.svg"
          />
        </div>
        <div>
          <p>{i18n.message("cardTeam.titulo.lider", "Líder")}</p>
          <span>
            {leader
              ? leader
              : i18n.message("cardTeam.titulo.sem.lider", "Sem líder")}
          </span>
        </div>
      </main>
      <footer className="cardTeam__footer">
        <p>
          {membersCount} {i18n.message("cardTeam.membros", "membro(s)")}
        </p>
        <BotaoEntrarTeam
          id={"botao-entrar-time"}
          funcao={() => {
            getInTeam(uuid, name);
          }}
          nome={i18n.message("cardTeam.btn.entrarTime", "Entrar na Equipe")}
          disabledOnClick={false}
          ariaLabelMsg={i18n.message(
            "cardTeam.btn.entrarTime",
            "Entrar na Equipe",
          )}
          ariaStatusMessage={i18n.message(
            "ariaStatus.cardTeam.btn.entrarTime",
            "Entrar na Equipe clicada",
          )}
          iconposition="right"
        />
      </footer>
    </CardStyle>
  );
}

export default SelectTeamCard;
