import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useStore } from "react-redux";
import PrimarioBtn from "../buttonPWA2.0/PrimarioBtn";
import { MissaoCumpridaFeedbackModalStyle } from "./StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "./WeexModalDialog";
import CampanhaVirtualService from "../../services/CampanhaVirtualService";

function ModalCampanhaVirtual({
  mostrarModal,
  close,
  url,
  nome,
  codigo,
  dataFim,
  dataInicio,
  enrollment,
  unlocked = true,
}) {
  const i18n = useStore().getState().i18n;
  const dialogRef = useRef(null);
  const dispatch = useDispatch();
  const mudancaModalVirtual = useStore().getState().mudancaModalVirtual;

  const campanhaVirtualService = useMemo(
    () => new CampanhaVirtualService(),
    [],
  );

  useEffect(() => {}, []);

  function voltar() {
    dispatch({ type: "mudancaModalVirtual", payload: mudancaModalVirtual + 1 });
    dialogRef.current.close();
    close();
  }

  function acessarCampanha() {
    const url = campanhaVirtualService.redirectToCampaign(codigo, enrollment);
    window.open(url, "_blank");
  }

  return mostrarModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={voltar}>
      <div
        className="modal is-active animations-popIn"
        style={{ zIndex: "102" }}
      >
        <div className="modal-background"></div>
        <div style={{ padding: "20px", paddingBottom: "50px" }}>
          <header className="modal-card-head weex-modal-card-header">
            <button
              className="close-btn"
              style={{ zIndex: "1003" }}
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
              onClick={voltar}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <MissaoCumpridaFeedbackModalStyle>
            <div
              id="missao-cumprida-container-confetes"
              className="missao-cumprida-container-confetes"
            ></div>
            <div>
              <div className="modal-card-title wex-modal-card-img">
                <div>
                  {url && (
                    <img
                      src={url}
                      alt={i18n.message(
                        "alt.campanhaVirtual.imagem",
                        "imagem de capa",
                      )}
                      className={`weex__image-virtual ${unlocked ? "" : "disabled"}`}
                    ></img>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                marginBottom: "2rem",
              }}
            >
              <div
                className="campanha-titulo"
                dangerouslySetInnerHTML={{ __html: nome }}
              />

              <div className="campanha-datas">
                <p className="campanha-data-texto">
                  {i18n.message(
                    "campanhaVirtual.dataInicio",
                    "Data de início: ",
                  )}
                  <br className="mobile-break" />
                  <span className="campanha-data-valor">
                    {new Date(dataInicio).toLocaleDateString("pt-BR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </span>
                </p>
                <p className="campanha-data-texto">
                  {i18n.message("campanhaVirtual.dataFim", "Data de término: ")}
                  <br className="mobile-break" />
                  <span className="campanha-data-valor">
                    {new Date(dataFim).toLocaleDateString("pt-BR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </span>
                </p>
              </div>

              {unlocked ? (
                <PrimarioBtn
                  nome={i18n.message("campanhaVirtual.btn.acessar", "Acessar")}
                  ariaLabelMsg={i18n.message(
                    "campanhaVirtual.btn.acessar",
                    "Acessar campanha",
                  )}
                  funcao={acessarCampanha}
                  classeComplementar={`weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4 ${unlocked ? "" : "disabled"}`}
                  disabled={!unlocked}
                  style={{
                    borderRadius: "6px",
                    padding: "0.75rem 1.5rem",
                    fontWeight: "500",
                    transition: "all 0.2s ease",
                  }}
                />
              ) : (
                <div className="campanha-bloqueada-texto">
                  Campanha bloqueada: a campanha tem uma outra campanha como
                  pré-requisito.
                </div>
              )}
            </div>
          </MissaoCumpridaFeedbackModalStyle>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default ModalCampanhaVirtual;
