import axios from "axios";
import UsuarioService from "./UsuarioService";

let instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API,
  timeout: 20000,
});

instance.interceptors.request.use((config) => {
  let token = new UsuarioService().token();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      //se a rota atual ja for o "/" nao redireciona

      if (window.location.pathname === "/") {
        return Promise.reject(error);
      }
      new UsuarioService().logout(() => {
        window.location = "/";
      });
    }
    return Promise.reject(error);
  },
);

export default instance;
