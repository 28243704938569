import instance from "./Api";
import CampanhaService from "./CampanhaService";
import TeamService from "./TeamService";
import UsuarioService from "./UsuarioService";

export default class RankingEquipesService {
  teamUuid = new UsuarioService().getUserTeamUuid();
  campanha = new CampanhaService().campanhaCorrente();
  teamService = new TeamService();

  getRankingEquipes(filtros) {
    let params = new URLSearchParams();

    if (filtros.page) {
      params.append("page", filtros.page);
    }
    if (filtros.pageSize) {
      params.append("pageSize", filtros.pageSize);
    }
    params.append("teamName", filtros.teamName);

    const campanhaService = new CampanhaService();
    const campanhaCorrente = campanhaService.campanhaCorrente();

    return instance.get(
      this.host() + "/api/v1/event/" + campanhaCorrente.code + "/team/ranking",
      {
        params,
      },
    );
  }

  getMyTeam() {
    if (this.teamUuid === null) {
      return Promise.reject(new Error("Não há equipes na sessão atual"));
    }
    return this.teamService.getTeam(this.campanha.code, this.teamUuid);
  }

  editTeamName(name) {
    return this.teamService.editTeam(
      this.teamUuid,
      null,
      name,
      this.campanha.code,
    );
  }

  editTeamLogo(image, name) {
    return this.teamService.editTeam(
      this.teamUuid,
      image,
      name,
      this.campanha.code,
    );
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
