import React, { useCallback, useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector, useStore } from "react-redux";
import ChangingProgressProvider from "../../WeexMarcadorProgresso/ChangingProgressProvider";
import { TrofeuDaCampanhaContainerStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";
import { useNavigate } from "react-router-dom";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
import RankingCampanha from "../../svg/RankingCampanha";
import RankingGamer from "../../svg/RankingGamer";
import WeexModalAtividades from "../../weexModais/WeexModalAtividades";
import RoletaTicketsService from "../../../services/RoletaTicketsService";
import UsuarioService from "../../../services/UsuarioService";

/**
 * Mostra o trofeu da campanha é a porcentagem total concluída
 * @param {Object} props
 * @param {String} props.url
 * @param {Object} props.trofeus
 */
export default function TrofeuCampanha({ url, trofeus }) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const [rota, setRota] = useState("");
  const [codigoAtividade, setCodigoAtividade] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false);
  const dispatch = useDispatch();
  const roleta = campanhaService.getRoleta(null, "CAMPAIGN_ROULETTE");
  const [apresentacaoModal, setapresentacaoModal] = useState({
    apresentacao: "",
    apresentacaoCapa: "",
    nome: "",
    titulo: "",
    icone: "",
    executed: false,
  });

  const usuarioCorrente = new UsuarioService().usuarioCorrente();

  const [loadStatus, setLoadStatus] = useState({
    loaded: false,
    loading: false,
  });

  const ticketsChanger = useSelector((state) => state.ticketsChanger);

  let campaignType;
  let showRankingGame;
  let anonimized;
  let mostarCertificado = false;
  let ocultarRanking = false;
  let exibirHistoricoRoleta = campanhaCorrente.roulettes?.length > 0;

  const carregarTickets = useCallback(async () => {
    if (loadStatus.loaded || loadStatus.loading) return;

    try {
      setLoadStatus((prev) => ({ ...prev, loading: true }));

      //obter todas as roletas

      const tickets = [];

      const roletaTicketService = new RoletaTicketsService();
      const roletaTicketsResponse = await roletaTicketService.obterTickets();

      if (roletaTicketsResponse?.data) {
        tickets.push(...roletaTicketsResponse.data.tickets);
      }

      dispatch({ type: "ticketsSorteados", payload: tickets });
      setLoadStatus((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      setLoadStatus((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usuarioCorrente?.participantCode) {
      carregarTickets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsChanger]);

  if (campanhaCorrente) {
    campaignType = campanhaCorrente?.campaignType;
    showRankingGame = campanhaCorrente?.showRankingGame;
    anonimized = campanhaCorrente?.anonimized;

    if (campanhaCorrente.personalizationEvent) {
      const dataDeExibicao =
        campanhaCorrente.personalizationEvent?.SHOW_CERTIFICATE;

      ocultarRanking =
        campanhaCorrente.personalizationEvent?.HIDE_RANKING === "true";
      //2024-10-02T03:00:00.000Z
      if (dataDeExibicao) {
        const dataAtual = new Date();
        const dataExibicao = new Date(dataDeExibicao);
        if (dataAtual >= dataExibicao) {
          mostarCertificado = true;
        }
      }
    }
  }

  const numeroTotalDeAtividades = trofeus.schedule.reduce(
    (total, trofeuAgenda) => total + trofeuAgenda.activities.length,
    0,
  );

  const numeroDeAtividadesFeitas = trofeus.schedule.reduce(
    (total, trofeuAgenda) =>
      total +
      trofeuAgenda.activities.filter((activity) => activity.achieved === true)
        .length,
    0,
  );

  const completionPercentage =
    (numeroDeAtividadesFeitas * 100) / numeroTotalDeAtividades;
  const porcentagemFinal = completionPercentage.toFixed(0);

  if (url === null) {
    return <div></div>;
  }

  const mostrarRoleta = () => {
    const roleta = campanhaService.getRoleta(null, "CAMPAIGN_ROULETTE");
    return roleta?.campaignTrophy;
  };

  const hasRoletaCampanha = mostrarRoleta();

  const navegarRoleta = () => {
    if (roleta) {
      if (roleta.showPresentation) {
        setapresentacaoModal({
          apresentacao: roleta?.presentation || roleta.description,
          apresentacaoCapa: roleta.url,
          nome: roleta?.name,
          titulo: roleta?.title,
          icone: "",
          executed: false,
        });
        setRota("roleta");
        setCodigoAtividade(null);
        setMostrarModal(true);
        return;
      }
      irParaRoleta();
    }
  };

  function irParaRoleta() {
    dispatch({ type: "scheduleCode", payload: null });
    dispatch({ type: "SHOW_MODAL", payload: "roulette" });
    dispatch({ type: "tipoRoleta", payload: "CAMPAIGN_ROULETTE" });
  }
  function ocultarPopup() {
    setMostrarModal(false);
  }

  return (
    <TrofeuDaCampanhaContainerStyle $porcentagemFinal={porcentagemFinal}>
      {mostrarModal ? (
        <WeexModalAtividades
          fecharModalCallback={ocultarPopup}
          irParaDinamica={irParaRoleta}
          rota={rota}
          conteudo_dangerouslySetInnerHTML={apresentacaoModal?.apresentacao}
          imagemSrc={apresentacaoModal?.apresentacaoCapa}
          atvName={apresentacaoModal?.nome}
          atvTitle={apresentacaoModal?.titulo}
          codigoAtividade={codigoAtividade}
          atvExecuted={apresentacaoModal?.executed}
        />
      ) : null}{" "}
      <div className="weex-l-trofeu-campanha-progress-bar-container animations-popIn">
        <ChangingProgressProvider
          values={[0, porcentagemFinal !== "NaN" ? porcentagemFinal : 0]}
          repeat={false}
        >
          {(porcentagemFinal) => (
            <CircularProgressbarWithChildren
              value={porcentagemFinal}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: porcentagemFinal === "100" ? "#00E291" : `#847D92`,
                  strokeLinecap: "round",
                  transition: "stroke-dashoffset 1.0s ease 0s",
                },
                trail: {
                  stroke: "#DFDFDF",
                },
              }}
            >
              <div className="weex-l-trofeu-campanha-informacoes">
                <img
                  style={{ maxWidth: "3.438rem", maxHeight: "3.438rem" }}
                  src={url}
                  alt={i18n.message(
                    "alt.trofeu.campaign",
                    "Imagem da taça de conclusão da campanha",
                  )}
                />
                <div
                  aria-hidden="true"
                  className="weex-l-trofeu-campanha-informacoes__textos-container"
                >
                  <p className="weex-l-trofeu-campanha-informacoes__textos">{`${porcentagemFinal}%`}</p>
                  <p className="weex-l-trofeu-campanha-informacoes__textos">
                    {i18n.message("geral.porcentagem.concluido", "Concluído")}
                  </p>
                </div>
                <span className="weex__a11y--visuallyHidden">{`${porcentagemFinal}% ${i18n.message(
                  "geral.porcentagem.concluido",
                  "Concluído",
                )}`}</span>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </ChangingProgressProvider>
      </div>
      {mostarCertificado ? (
        <div className="button-certificado-campanha">
          <PrimarioBtn
            ariaDisabled="true"
            ariaHidden="true"
            tabIndex="-1"
            nome={i18n.message(
              "visualizar.certificado",
              "VISUALIZAR CERTIFICADO",
            )}
            funcao={() => navigate("/certificado")}
            ariaStatusMessage={i18n.message(
              "visualizar.certificado",
              "VISUALIZAR CERTIFICADO",
            )}
          ></PrimarioBtn>
        </div>
      ) : (
        ""
      )}
      {campaignType !== "REGULAR" && !anonimized && !ocultarRanking ? (
        <div style={{ width: "100%" }} className="button-ranking-campanha">
          <PrimarioBtn
            nome={i18n.message(
              "trofeu.ranking.campanha",
              "RANKING DA COMPETIÇÃO",
            )}
            funcao={() =>
              navigate(
                campaignType === "EQUIPE"
                  ? "/campanhaRankingPorEquipe"
                  : "/campanhaRanking",
              )
            }
            ariaStatusMessage={i18n.message(
              "aria.status.trofeus.botaoverrankingcampanha",
              "Botão ver ranking campanha clicado.",
            )}
          >
            {RankingCampanha}
          </PrimarioBtn>
        </div>
      ) : (
        ""
      )}
      {showRankingGame && !anonimized ? (
        <div style={{ width: "100%" }} className="button-certificado-campanha">
          <PrimarioBtn
            ariaDisabled="true"
            ariaHidden="true"
            tabIndex="-1"
            nome={i18n.message("trofeu.ranking.game", "VER RANKING DO GAME")}
            funcao={() => navigate("/gameRankings")}
            ariaStatusMessage={i18n.message(
              "aria.status.trofeus.botaoverrankinggame",
              "Botão ver ranking game clicado.",
            )}
          >
            {RankingGamer}
          </PrimarioBtn>
        </div>
      ) : (
        ""
      )}
      {hasRoletaCampanha && porcentagemFinal === "100" ? (
        <div className="button-certificado-campanha">
          <PrimarioBtn
            ariaDisabled="true"
            ariaHidden="true"
            tabIndex="-1"
            nome={i18n.message("roleta.campanha", "ROLETA DA CAMPANHA")}
            funcao={navegarRoleta}
            ariaStatusMessage={i18n.message(
              "roleta.campanha",
              "ROLETA DA CAMPANHA",
            )}
          ></PrimarioBtn>
        </div>
      ) : (
        ""
      )}
      {exibirHistoricoRoleta ? (
        <div style={{ width: "100%" }} className="button-certificado-campanha">
          <PrimarioBtn
            ariaDisabled="true"
            ariaHidden="true"
            tabIndex="-1"
            nome={i18n.message("visualizar.roleta", "HISTÓRICO DA ROLETA")}
            funcao={() => navigate("/rouletteHistory")}
            ariaStatusMessage={i18n.message(
              "visualizar.roleta",
              "HISTÓRICO DA ROLETA",
            )}
          ></PrimarioBtn>
        </div>
      ) : (
        ""
      )}
    </TrofeuDaCampanhaContainerStyle>
  );
}
