import instance from "./Api";

export default class UnidadeService {
  host() {
    return process.env.REACT_APP_HOST_API;
  }

  /**
   * Busca as unidades de um cliente.
   * @param {string} uuid - UUID do cliente.
   * @param {number} id - ID do pai (opcional).
   * */
  getUnidades(clienteUuid, parentId, name) {
    const body = {
      active: true,
      accounting: true,
    };

    if (parentId) {
      body.parentId = parentId;
    }

    if (name) {
      body.name = name;
      body.mode = "leaf";
    }

    const url = this.host() + `/api/v1/client/${clienteUuid}/unitSearch`;
    return instance.post(url, body);
  }

  /**
   * Busca as unidades de um cliente.
   * @param {string} uuid - UUID do cliente.
   * */
  getUnidadesTreeView(uuid, mode = "on_demand", name = "") {
    const body = {
      isPwa: true,
      mode: mode,
      name: name,
    };

    const url = this.host() + `/api/v1/client/${uuid}/unitSearch`;
    return instance.post(url, body);
  }

  prepareNode(response) {
    if (!response || response.length === 0) return [];

    return response.map((element) => ({
      ...this.nodeObj(element),
      children: element.subUnits ? this.prepareNode(element.subUnits) : [],
    }));
  }

  nodeObj(element) {
    return {
      uuid: element.uuid,
      label: element.name,
    };
  }
}

/*

{
    key: '0',
    label: 'Documents',
    data: 'Documents Folder',
    icon: 'pi pi-fw pi-inbox',
    children: [
        {
            key: '0-0',
            label: 'Work',
            data: 'Work Folder',
            icon: 'pi pi-fw pi-cog',
            children: [
                { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
            ]
        },
        {
            key: '0-1',
            label: 'Home',
            data: 'Home Folder',
            icon: 'pi pi-fw pi-home',
            children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
        }
    ]
},

*/
