import lottie from "lottie-web";
import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RoletaGanhouCardStyle } from "../style";
import RoletaTicketsService from "../../../services/RoletaTicketsService";
import PrimarioBtn from "../../buttonPWA2.0/PrimarioBtn";
export function PontuacaoRoleta({
  title,
  subtitle,
  pointsEarned,
  competitiveType,
  brindeUuid,
  ocultar,
  audioRef,
}) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const [img, setBrindeImg] = useState(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("confete"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/confete.json",
    });
  }, []);

  useEffect(() => {
    audioRef.current.play();
    audioRef.current.volume = 0.3; // Volume reduzido (30%)
  }, [audioRef]);

  useEffect(() => {
    const roletaTicketsService = new RoletaTicketsService();
    const fetchImagemBrind = async () => {
      try {
        const response = await roletaTicketsService.getFile(
          "GIVEAWAY",
          "file",
          brindeUuid,
        );

        const url = URL.createObjectURL(response.data);
        setBrindeImg(url);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchImagemBrind();
  }, [brindeUuid]);

  const goHistorico = () => {
    let i18nMessage = i18n.message("visualizar.roleta", "HISTÓRICO DA ROLETA");

    let i18nAriaStatus = i18n.message(
      "visualizar.roleta",
      "HISTÓRICO DA ROLETA",
    );

    return (
      <>
        <section className="weexDialog__buttonsContainer weexDialog__container--alignment">
          {/*<BotaoRoleta*/}
          {/*  // nome={i18nMessage}*/}
          {/*  // funcao={() => {*/}
          {/*  //   audioRef.current.pause(); // Para o áudio*/}
          {/*  //   // eslint-disable-next-line react-hooks/exhaustive-deps*/}
          {/*  //   audioRef.current.currentTime = 0; // Reseta o áudio para o início*/}
          {/*  //   ocultar();*/}
          {/*  //   navigate("/rouletteHistory");*/}
          {/*  // }}*/}
          {/*  // ariaStatusMessage={i18nAriaStatus}*/}
          {/*  // disabledOnClick={false}*/}
          {/*//>*/}

          <PrimarioBtn
            nome={i18nMessage}
            disabled={false}
            funcao={() => {
              audioRef.current.pause(); // Para o áudio
              // eslint-disable-next-line react-hooks/exhaustive-deps
              audioRef.current.currentTime = 0; // Reseta o áudio para o início
              ocultar();
              navigate("/rouletteHistory");
            }}
            ariaStatusMessage={i18nAriaStatus}
          ></PrimarioBtn>
        </section>
      </>
    );
  };
  return (
    <>
      <main className="weexDialog__main" id="confete">
        <section>
          {title && (
            <h1 className="weexDialog__texts--alignment weexDialog__title">
              {title}
            </h1>
          )}
          {subtitle && (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              <span className="weexDialog__texts--alignment weexDialog__texts">
                {subtitle}
              </span>
            </div>
          )}
        </section>
        <RoletaGanhouCardStyle>
          {img ? (
            <div className="roleta-image-container roleta-image-container--premio">
              <img
                src={img}
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          ) : (
            <div className="roleta-image-container roleta-image-container--icone">
              <img
                src="/svgs-3.0/gift.png"
                alt={i18n.message(
                  "alt.roleta.gift",
                  "Ícone de um presente embrulhado",
                )}
              />
            </div>
          )}
          {pointsEarned && (
            <div className="pontuacao-container">
              <div>
                <span>
                  <h1 className="pontuacao">
                    {" "}
                    + {pointsEarned}{" "}
                    {pointsEarned === 1
                      ? i18n.message("geral.pt", "PT")
                      : i18n.message("geral.pts", "PTS")}
                  </h1>
                </span>
              </div>
              <br></br>
            </div>
          )}
        </RoletaGanhouCardStyle>
        {goHistorico()}
      </main>
    </>
  );
}
