import React from "react";
import { useStore, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import BotaoTerciario from "../../button/BotaoTerciario";
import Exit from "../../svg/botoes/Exit";
import { DropdownMenuStyle } from "./style";
/**
 * @param {Object} props
 * @param {function(): void} props.toggle
 * @param {function(): void} props.sair
 * @param {Date} props.now
 */
function DropdownMenu({ now, toggle, toggleMenu, sair }) {
  const i18n = useStore().getState().i18n;

  const usuariologado = useSelector((state) => state.usuariologado);

  let location = useLocation();

  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  console.log("campanhaCorrente", campanhaCorrente);
  const notLegalTerm = !usuariologado?.legalTerm;
  const locationPathname = location?.pathname;
  const isVirtualCampaignPage =
    campanhaService.isRecurrentCampaign() &&
    locationPathname === "/campaign/virtual";
  const beginDate = campanhaService.getBeginDate();
  let tipoInscricao;
  let requiredInterests;
  let comoFunciona;
  if (campanhaCorrente) {
    tipoInscricao = campanhaCorrente.tipoInscricao;
    requiredInterests = campanhaCorrente.requiredInterests;
    comoFunciona = campanhaCorrente.comoFunciona;
  }

  const exporComoFunciona =
    comoFunciona && comoFunciona.helpMessage && !isVirtualCampaignPage;

  const homeLocked =
    (tipoInscricao === "ELEGIBILIDADE" && notLegalTerm) ||
    (requiredInterests === true &&
      locationPathname === "/interests" &&
      beginDate <= now);

  const home = () => {
    if (!homeLocked && campanhaCorrente?.code) {
      return (
        <li>
          <Link to="/" onClick={toggle} className="dropdownMenuStyle__link">
            {i18n.message("geral.menu.inicio", "Início")}
          </Link>
        </li>
      );
    }
  };

  const howItWorks = () => {
    if (exporComoFunciona) {
      return (
        <li>
          <Link to="/help" onClick={toggle} className="dropdownMenuStyle__link">
            {i18n.message("geral.menu.comoFunciona", "Como funciona")}
          </Link>
        </li>
      );
    }
  };

  return (
    <DropdownMenuStyle id="dropdown-menu" $toggleMenu={toggleMenu}>
      <ul className="dropdownMenuStyle__list">
        {home()}
        {howItWorks()}
        <li className="dropdownMenuStyle__logout">
          <BotaoTerciario
            size="small"
            funcao={sair}
            nome={i18n.message("geral.menu.sair", "Sair")}
            disabledOnClick={false}
            iconposition="right"
            iconRight={
              <>
                <Exit />
              </>
            }
          />
        </li>
      </ul>
    </DropdownMenuStyle>
  );
}

export default DropdownMenu;
