import instance from "./Api";

export default class NotificadorService {
  notificadorUrl = process.env.REACT_APP_HOST_NOTIFICADOR;

  enviarTokenParaNotificador({ token, userId, name, enrollment }) {
    const body = {
      token: token,
      userId: userId,
      name: name,
      enrollment: enrollment,
    };

    return instance.post(this.notificadorUrl + "/tokens", body);
  }

  marcarMensagemComoLida(mensagemId, data) {
    const body = {
      readDate: data,
    };

    instance
      .put(`${this.notificadorUrl}/mensagens/${mensagemId}/read`, body)
      .then((response) => {
        console.log("Mensagem marcada como lida com sucesso");
      });
  }

  buscarMensagensParticipante({
    userId,
    status,
    event,
    page,
    limit,
    tag,
    action,
  }) {
    const searchParams = new URLSearchParams();
    searchParams.append("participantUUID", userId);

    if (status) {
      searchParams.append("status", status);
    }

    searchParams.append("page", page || 1);

    searchParams.append("limit", limit || 100);

    if (event) {
      searchParams.append("event", event);
    }

    if (tag) {
      searchParams.append("tag", tag);
    }

    if (action) {
      searchParams.append("action", action);
    }

    return instance.get(`${this.notificadorUrl}/mensagens`, {
      params: searchParams,
    });
  }

  removerTokenDeNotificador(token) {
    const body = {
      token: token,
    };
    return instance.delete(`${this.notificadorUrl}/tokens`, { data: body });
  }
}
