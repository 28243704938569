import styled from "styled-components";

export const CampaignStyle = styled.main`
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgb(144 144 144 / 33%) transparent;

  padding: 1.25rem;
  border-radius: 1.25rem;
  background-color: rgba(144, 144, 144, 0.1);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem; //16px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  .atividadesDia__subheader {
    display: flex;
    align-items: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    gap: 2.5rem; // 40px;
  }
  /* #endregion DESKTOP */
`;

export const CampaignButtonStyle = styled.a`
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  transition: transform 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  :active {
    filter: brightness(0.5);
  }
  :hover {
    filter: brightness(0.5);
    transform: scale(1.1);
  }

  .campaignButton__mobile {
    max-width: 6.125rem; // 98px;
    max-height: auto;
    border-radius: 0.25rem; // 4px;
  }

  .campaignButton__desktop {
    max-width: 18.063rem; // 289px;
    max-height: auto;
    border-radius: 0.25rem; // 4px;
  }
`;
