import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";

function TeamLogoListagem({ uuid, url }) {
  const i18n = useStore().getState().i18n;
  const [logoSrc, setLogoSrc] = useState(null);

  const getLogo = () => {
    if (!uuid) {
      return;
    }

    if (url) {
      setLogoSrc(url);
    }
  };

  useEffect(() => {
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (logoSrc !== null) {
    return (
      <img
        className="cardTeam__imageContainer--logo"
        alt={i18n.message("aria.cardTeam.icone.equipe", "ícone da equipe")}
        src={logoSrc}
      />
    );
  } else {
    return (
      <img
        className="cardTeam__imageContainer--logoDefault"
        alt={i18n.message(
          "aria.cardTeam.icone.equipe.padrao",
          "ícone com três usuario simbolizando uma equipe",
        )}
        src="/team.svg"
      />
    );
  }
}

export default TeamLogoListagem;
