// import instance from "./Api";
import instance from "./Api";
import UsuarioService from "./UsuarioService";

export default class RoletaTicketsService {
  usuarioService = new UsuarioService();

  /**
   * Obtém os tickets da roleta.
   * @returns {Promise<Object>} - Retorna uma Promise com os tickets (mock).
   */
  async obterTickets() {
    const usuarioService = new UsuarioService();
    const participanteUuid = usuarioService.usuarioCorrente()?.participantCode;
    return instance.get(
      this.host() + `/api/v1/participant/${participanteUuid}/tickets`,
    );
  }

  async getFile(model, field, identifier) {
    return instance.get(this.host() + "/api/v1/files", {
      params: { model, field, identifier },
      responseType: "blob",
    });
  }

  spinRoulette(agendaUuid, tipo) {
    const body = {};
    body.scheduleUuid = agendaUuid;
    body.type = tipo;
    const codigoParticipante = this.usuarioService?.getCodigoParticipante();
    return instance.post(
      this.host() + `/api/v1/draws/${codigoParticipante}`,
      body,
    );
  }

  markVoucherAsRead(giveawayUuid, voucherUuid) {
    return instance.put(
      this.host() +
        `/api/v1/giveaway/${giveawayUuid}/voucher/${voucherUuid}/read`,
    );
  }

  /**
   * Obtém a URL base da API.
   * @returns {string} - URL da API.
   */
  host() {
    return process.env.REACT_APP_HOST_API;
  }
}

/**
 * Representa uma Ticket com informações detalhadas sobre um evento ou participante.
 *
 * @typedef {Object} Ticket
 * @property {string} uuid - Identificador único.
 * @property {string} participantUuid - Identificador único do participante.
 * @property {string} participantName - Nome do participante.
 * @property {string} enrollment - Matrícula do participante.
 * @property {string} unit - Unidade associada ao participante.
 * @property {string} subUnit - Subunidade associada ao participante.
 * @property {string} level3 - Informações do nível 3 (caso aplicável).
 * @property {Date} date - Data do evento.
 * @property {string} giveawayUuid - Identificador único do sorteio.
 * @property {string} giveawayName - Nome do sorteio.
 * @property {string} voucherCode - Código do voucher associado.
 * @property {string} voucherUuid - Identificador único do voucher.
 * @property {string} proofUuid - Identificador único da prova (caso aplicável).
 * @property {string} action - Ação associada ao evento.
 * @property {string} trophyUuid - Identificador único do troféu.
 * @property {string} scheduleUuid - Identificador único do cronograma.
 * @property {string} scheduleName - Nome do cronograma.
 * @property {string} giveawayType - Tipo de sorteio associado.
 * @property {boolean} isRead - Indica se o ticket foi lido.
 */
