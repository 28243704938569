import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import CampanhaVirtualService from "../../services/CampanhaVirtualService";
import Theme from "../../comps/VirtualCampaign/Theme";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import Plus from "../../comps/svg/botoes/Plus";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";
import CampanhaService from "../../services/CampanhaService";
export default function VirtualCampaign() {
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const [campanhasVirtuais, setCampanhasVirtuais] = useState({});
  const [page, setPage] = useState(1);
  const [mostrarMais, setMostrarMais] = useState(false);
  const campanhaService = useMemo(() => new CampanhaService(), []);
  const mudancaModalVirtual = useSelector((state) => state.mudancaModalVirtual);

  useEffect(() => {
    console.log("mudancaModalVirtual", mudancaModalVirtual);
    getVirtualCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mudancaModalVirtual]);

  const campanhaVirtualService = useMemo(
    () => new CampanhaVirtualService(),
    [],
  );

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    const sincronizarCampanha = async () => {
      try {
        await campanhaService.useSincronizarCampanha();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha();
    console.log("sincronizarCampanha");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVirtualCampaigns = useCallback(
    (carregarMais = false, searchFilters) => {
      campanhaVirtualService
        .obterCampanhasVirtuais(searchFilters)
        .then((response) => {
          if (response.data) {
            const eventsByTheme = campanhaVirtualService.groupEventsByTheme(
              response?.data?.events,
            );

            if (response?.data?.totalPages !== page) {
              setMostrarMais(true);
            }
            response.data.eventsByTheme = eventsByTheme;
            if (carregarMais) {
              setCampanhasVirtuais((prevState) => ({
                ...prevState,
                ...response.data,
                eventsByTheme: [
                  ...(prevState.eventsByTheme || []),
                  ...(response.data.eventsByTheme || []),
                ],
                events: [
                  ...(prevState.events || []),
                  ...(response.data.events || []),
                ], // Mescla arrays dentro do objeto
              }));
            } else {
              setCampanhasVirtuais(response.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "virtualCampaign.erro.title",
                "Erro ao retornar campanhas recorrentes",
              ),
              message: i18n.message(
                "virtualCampaign.erro.message",
                "Erro ao retornar campanhas recorrentes. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
              ),
            },
          });
          return;
        });
    },
    [campanhaVirtualService, dispatch, i18n, page],
  );

  useEffect(() => {
    const searchFilters = {
      pageSize: 20,
      page: 1,
    };
    getVirtualCampaigns(false, searchFilters);
  }, [campanhaVirtualService, getVirtualCampaigns]);

  const showMore = () => {
    let nextPage = page + 1;
    setPage(nextPage);
    const searchFilters = {
      pageSize: 20,
      page: nextPage,
    };
    getVirtualCampaigns(true, searchFilters);
  };

  return (
    <>
      <PagesHeader title={i18n.message("virtualCampaign.title", "Campanhas")} />
      {campanhasVirtuais?.eventsByTheme?.map((campanhaVirtual) => {
        return (
          <div key={campanhaVirtual.uuid}>
            <Theme themeEvent={campanhaVirtual?.themeEvent} />
          </div>
        );
      })}
      <footer>
        {mostrarMais && (
          <PrimarioBtn
            id="botao-vermais-campanhas-virtuais"
            funcao={showMore}
            nome={i18n.message("virtualCampaign.btn.vermais", "Ver mais")}
            disabled={false}
            disabledOnClick={false}
            ariaLabelMsg={i18n.message(
              "virtualCampaign.btn.vermais",
              "Ver mais",
            )}
            ariaStatusMessage={i18n.message(
              "ariaStatus.virtualCampaign.btn.vermais",
              "Ver mais clicado",
            )}
            iconposition="right"
            iconRight={
              <>
                <Plus />
              </>
            }
          />
        )}
      </footer>
    </>
  );
}
