import { getDataAgenda } from "../../comps/agenda/CardzinhoDataDaAgenda/DataAgendaUtils";

export function buildDataAgenda(tickets, i18n, newProperties) {
  for (const ticket of tickets) {
    const { dayOfWeekComplete, mesTextualLeitorTela, mesTextualVisivel, day } =
      getDataAgenda(ticket.date, i18n);

    newProperties.set(ticket.uuid, {
      uuid: ticket.uuid,
      uuidBrinde: ticket.giveawayUuid,
      dayOfWeekComplete,
      mesTextualLeitorTela,
      mesTextualVisivel,
      day,
    });
  }
  return newProperties;
}

export function buildActionsTickets(
  tickets,
  currentProperties,
  i18n,
  recVaucherFn,
  compFn,
) {
  const newProperties = new Map(currentProperties);
  for (const ticket of tickets) {
    switch (ticket.giveawayType) {
      case "VOUCHER":
        newProperties.set(ticket.uuid, {
          ...newProperties.get(ticket.uuid),
          btnLabel: i18n.message("roleta.recuperaVaucher", "RECUPERAR VAUCHER"),
          action: recVaucherFn,
        });
        break;
      case "BRINDE":
        newProperties.set(ticket.uuid, {
          ...newProperties.get(ticket.uuid),
          btnLabel: i18n.message(
            "roleta.baixaComprovante",
            "BAIXAR COMPROVANTE",
          ),
          action: () => compFn(newProperties.get(ticket.uuid)),
        });
        break;
      case "PONTOS":
      default:
        break;
    }
  }
  return newProperties;
}

/**
 * formata a unidade para exibição
 * formata a data do sorteio
 * */

export function buildFormatacaoTickets(tickets, currentProperties) {
  const newProperties = new Map(currentProperties);

  for (const ticket of tickets) {
    newProperties.set(ticket.uuid, {
      ...newProperties.get(ticket.uuid),
      unidadeFormatted: formataUnidade(
        ticket.unit,
        ticket?.subUnit,
        ticket?.level3,
      ),
      dataSorteioFormatted: formatarData(new Date(ticket.date)),
      giveawayName: ticket.giveawayName,
      participantName: ticket.participantName,
      enrollment: ticket.enrollment,
    });
  }

  return newProperties;
}

function formataUnidade(unidade, subunidade, nivel3) {
  if (nivel3) {
    return `${unidade} / ${subunidade} / ${nivel3}`;
  } else if (subunidade) {
    return `${unidade} / ${subunidade}`;
  } else if (unidade) {
    return unidade;
  } else {
    return "";
  }
}

function formatarData(data) {
  if (data) {
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Os meses começam do zero
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    return `${dia}/${mes}/${ano} às ${horas}:${minutos}`;
  }

  return "";
}
