import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { CampaignButtonStyle } from "./campaignStyle";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import ModalCampanhaVirtual from "../weexModais/ModalCampanhaVirtual";
import { CampaignVirtualStyle } from "./CampaignVirtualStyle";

export default function Campaign({ event }) {
  const usuariologado = useSelector((state) => state.usuariologado);
  const i18n = useStore().getState().i18n;
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [isBloqueado, setIsBloqueado] = useState(false);

  useEffect(() => {
    validarCampanhaDesbloqueada();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const validarCampanhaDesbloqueada = () => {
    const dataFinal = new Date(event.dateFinish);
    const dataAtual = new Date();

    const dataDeFimMenorQueDataAtual =
      dataFinal.getTime() < dataAtual.getTime();
    if (!event.unlocked || dataDeFimMenorQueDataAtual) {
      setIsBloqueado(true);
    } else {
      setIsBloqueado(false);
    }
  };

  return (
    <CampaignVirtualStyle>
      <ModalCampanhaVirtual
        mostrarModal={mostrarModal}
        close={() => setMostrarModal(false)}
        url={isDesktopMode ? event?.desktopCover : event?.mobileCover}
        nome={event?.name}
        codigo={event?.code}
        dataFim={event?.dateFinish}
        dataInicio={event?.dateInit}
        enrollment={usuariologado?.enrollment}
        unlocked={event?.unlocked}
      ></ModalCampanhaVirtual>
      <button
        className={`weex__buttonLink ${!isBloqueado ? "" : "disabled disabled-cursor"}`}
        onClick={() => (!isBloqueado ? setMostrarModal(true) : () => {})}
      >
        <WeexMobileMode>
          {event?.mobileCover ? (
            <>
              <div style={{ marginBottom: "5px" }}>
                <span
                  className="text"
                  dangerouslySetInnerHTML={{ __html: event?.name }}
                />
              </div>
              <CampaignButtonStyle>
                <img
                  src={event?.mobileCover}
                  className={`campaignButton__mobile ${!isBloqueado ? "" : "disabled disabled-cursor"}`}
                  alt={i18n.message(
                    "alt.virtualCampaign.poster.mobile",
                    "Imagem do poster da campanha para mobile",
                  )}
                />
              </CampaignButtonStyle>
            </>
          ) : (
            <div>
              <span
                className="text"
                dangerouslySetInnerHTML={{ __html: event?.name }}
              />
            </div>
          )}
        </WeexMobileMode>
        <WeexDesktopMode>
          {event?.desktopCover ? (
            <>
              <div style={{ marginBottom: "10px" }}>
                <span
                  className="text"
                  dangerouslySetInnerHTML={{ __html: event?.name }}
                />
              </div>
              <CampaignButtonStyle>
                <img
                  src={event?.desktopCover}
                  className={`campaignButton__desktop ${!isBloqueado ? "" : "disabled disabled-cursor"}`}
                  alt={i18n.message(
                    "alt.virtualCampaign.poster.desktop",
                    "Imagem do poster da campanha para desktop",
                  )}
                />
              </CampaignButtonStyle>
            </>
          ) : (
            <div>
              <span
                className="text"
                dangerouslySetInnerHTML={{ __html: event?.name }}
              />
            </div>
          )}
        </WeexDesktopMode>
      </button>
    </CampaignVirtualStyle>
  );
}
