import styled from "styled-components";
export const CampaignVirtualStyle = styled.div`
  .disabled {
    filter: grayscale(100%);
  }
  .disabled-cursor {
    cursor: not-allowed !important;
  }
  /* Mobile-first base styles */
  .campanha-container {
    margin-top: 20px;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  .weex__image-virtual {
    width: 100px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }

  .campanha-bloqueada-texto {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    word-break: break-word;
  }

  .campanha-titulo {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  .campanha-datas {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }

  .campanha-data-texto {
    font-size: 0.875rem;
  }

  .campanha-data-valor {
    font-weight: 600;
  }

  .campanha-botao-acessar {
    width: 100%;
    margin-top: 1rem;
    font-weight: 500;
  }

  /* Tablet styles */
  @media (min-width: 768px) {
    .campanha-container {
      margin-top: 25px;
      padding: 1.25rem;
    }

    .campanha-titulo {
      font-size: 1.75rem;
    }

    .campanha-data-texto {
      font-size: 1rem;
    }
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    .campanha-container {
      margin-top: 35px;
      margin-bottom: 2rem;
      padding: 1.5rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    .campanha-titulo {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .campanha-datas {
      margin-bottom: 2rem;
    }

    .campanha-botao-acessar {
      width: auto;
      min-width: 200px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .weex__image-virtual {
      width: 300px;
      height: 170px;
      object-fit: cover;
      object-position: center;
    }
  }
  .campanha-data-texto {
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .campanha-data-valor {
    font-weight: 600;
    display: block;
    margin-top: 0.25rem;
  }

  /* Esconde a quebra de linha em telas maiores */
  @media (min-width: 768px) {
    .mobile-break {
      display: none;
    }

    .campanha-data-texto {
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }

    .campanha-data-valor {
      margin-top: 0;
      display: inline;
    }
  }
`;
