/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import RankingEquipesService from "../../services/RankingEquipesService";

const TeamLogo = ({ uuid, name, logoEquipe, isEditable = false }) => {
  const [logoSrc, setLogoSrc] = useState(null);
  const i18n = useStore().getState().i18n;
  const rankingEquipesService = new RankingEquipesService();
  const dispatch = useDispatch();

  const getLogo = () => {
    if (!uuid) {
      return;
    }

    if (logoEquipe) {
      setLogoSrc(logoEquipe);
    }
  };

  useEffect(() => {
    getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    rankingEquipesService
      .editTeamLogo(file, name)
      .then(() => getLogo())
      .catch((error) => {
        console.error("error", error);
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "selectTeam.logo.erro.title",
              "Erro ao editar logo da equipe",
            ),
            message: i18n.message(
              "selectTeam.logo.erro.message",
              error.response.data.message,
            ),
          },
        });
      });
  };

  const imageClick = () => {
    document.getElementById("upload-input-on-logo-team").click();
  };

  const teamLogo = () => {
    if (logoSrc !== null) {
      return (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
        <img
          className="cardTeam__imageContainer--logo"
          alt={i18n.message("aria.cardTeam.icone.equipe", "ícone da equipe")}
          src={logoSrc}
        />
      );
    } else {
      return (
        <img
          className="cardTeam__imageContainer--logoDefault"
          alt={i18n.message(
            "aria.cardTeam.icone.equipe.padrao",
            "ícone com três usuario simbolizando uma equipe",
          )}
          src="/team.svg"
        />
      );
    }
  };

  const teamLogoEdit = () => {
    return (
      <>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="upload-input-on-logo-team"
          onChange={handleImageChange}
        />
        <div className="logo__editable__container">
          <button
            className="cardTeam__imageContainer editable__card__imageContainer"
            onClick={imageClick}
          >
            {teamLogo()}
          </button>
          <button className="logo__button__icon" onClick={imageClick}>
            <i className="material-icons-round">add_a_photo</i>
          </button>
        </div>
      </>
    );
  };

  const teamLogoNotEdit = () => {
    return <div className="cardTeam__imageContainer">{teamLogo()}</div>;
  };

  return isEditable ? teamLogoEdit() : teamLogoNotEdit();
};

export default TeamLogo;
