import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import { MissaoCumpridaFeedbackModalStyle } from "./StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "./WeexModalDialog";
import { RoletaComprovanteHistoricoStyle } from "./style";
import SecundarioBtn from "../buttonPWA2.0/SecundarioBtn";
import RoletaTicketsService from "../../services/RoletaTicketsService";
const campanhaService = new CampanhaService();
function ModalRoletaComprovanteHistorico({
  brindeNome,
  nome,
  matricula,
  unidade,
  dataSorteio,
  fecharModal,
  brindeUuid,
  ticketUuid,
}) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const [processando, setProcessando] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(true);
  const dialogRef = useRef(null);
  const [brindeImg, setBrindeImg] = useState(null);
  let campanhaNome = campanhaService.campanhaCorrente().name;
  const [requestComprovante, setRequestComprovante] = useState(false);
  useEffect(() => {
    let temaService = new TemaService(campanhaService.campanhaCorrente());
    temaService.aplicarTema("HOME");
    if (!processando) {
      setProcessando(true);
      lottie.loadAnimation({
        container: document.getElementById(
          "missao-cumprida-container-confetes",
        ),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/confete.json",
      });
    }
  }, [processando]);

  useEffect(() => {
    const roletaTicketsService = new RoletaTicketsService();
    const fetchImagemBrind = async () => {
      try {
        const response = await roletaTicketsService.getFile(
          "GIVEAWAY",
          "file",
          brindeUuid,
        );

        const url = URL.createObjectURL(response.data);
        setBrindeImg(url);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchImagemBrind();
  }, [brindeUuid]);

  function voltar() {
    dialogRef.current.close();
    setMostrarModal(false);
    fecharModal();
    navigate("/rouletteHistory");
  }

  const baixarComprovante = async () => {
    if (requestComprovante) return;
    setRequestComprovante(true);
    const roletaTicketsService = new RoletaTicketsService();
    try {
      console.log("ticketUuid", ticketUuid);
      const response = await roletaTicketsService.getFile(
        "TICKET",
        "file",
        ticketUuid,
      );
      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "comprovante.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setRequestComprovante(false);
    }
  };

  return mostrarModal ? (
    <RoletaComprovanteHistoricoStyle>
      <WeexModalDialog
        className="comprovante-container"
        dialogRef={dialogRef}
        onPressESC={voltar}
      >
        <div
          className="modal is-active animations-popIn comprovante-container"
          style={{ zIndex: "102" }}
        >
          <div className="modal-background"></div>
          <div>
            <header className="modal-card-head weex-modal-card-header">
              <button
                className="close-btn"
                style={{ zIndex: "1003" }}
                aria-label={i18n.message(
                  "aria.label.fechar.modal",
                  "Fecha a janela e volta para a página.",
                )}
                onClick={voltar}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message(
                    "alt.button.imagem.fechar",
                    "ícone em formato de um X indicando o encerramento de uma ação.",
                  )}
                />
              </button>
            </header>
            <MissaoCumpridaFeedbackModalStyle>
              <div
                id="missao-cumprida-container-confetes"
                className="missao-cumprida-container-confetes"
              >
                <div>
                  <div className="card">
                    <div className="line"></div>
                    <div className="card-header">
                      {brindeImg ? (
                        <img
                          width="138"
                          height="102"
                          src={brindeImg}
                          alt={brindeNome || ""}
                        />
                      ) : (
                        <div style={{ width: "138px", height: "102px" }}></div>
                      )}

                      <div className="title">{brindeNome}</div>
                    </div>
                    <div className="card-body">
                      <div className="info">
                        <span className="label">
                          Campanha: <br />
                        </span>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: campanhaNome }}
                          className="valor"
                        ></span>
                      </div>
                      <div className="info">
                        <span className="label">
                          Nome: <br />
                        </span>{" "}
                        <span className="valor"> {nome}</span>
                      </div>
                      <div className="info">
                        <span className="label">
                          Matrícula/E-mail: <br />
                        </span>{" "}
                        <span className="valor">{matricula}</span>
                      </div>
                      {unidade && (
                        <div className="info">
                          <span className="label">
                            Unidade: <br />
                          </span>{" "}
                          <span className="valor"> {unidade}</span>
                        </div>
                      )}
                      <div className="info">
                        <span className="label">
                          Data do Sorteio:
                          <br />
                        </span>{" "}
                        <span className="valor"> {dataSorteio} </span>
                      </div>
                    </div>
                  </div>
                  <div className="ellipse-footer">
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                    <div className="ellipse"></div>
                  </div>
                </div>
                <SecundarioBtn
                  nome={i18n.message(
                    "roletaHistorico.baixarComprovante",
                    "BAIXAR COMPROVANTE",
                  )}
                  ariaLabelMsg={i18n.message(
                    "aria.label.fechar.modal",
                    "Fecha a janela e volta para a página.",
                  )}
                  funcao={baixarComprovante}
                  classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
                  ariaStatusMessage={i18n.message(
                    "aria.status.mensagemreutilizavel.botaovoltarclicado",
                    "Botão voltar clicado.",
                  )}
                />
              </div>
            </MissaoCumpridaFeedbackModalStyle>
          </div>
        </div>
      </WeexModalDialog>
    </RoletaComprovanteHistoricoStyle>
  ) : (
    <></>
  );
}

export default ModalRoletaComprovanteHistorico;
