import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import UsuarioService from "../../../services/UsuarioService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Usado para logins feitos através de campanhas virtuais
 */
function LoginIndireto() {
  const [code, setCode] = useState("");
  const usuarioService = useMemo(() => new UsuarioService(), []);
  const campanhaService = useMemo(() => new CampanhaService(), []);

  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const enrollment = query.get("enrollment");

  const eventoCorrente = campanhaService?.campanhaCorrente();
  const tipoInscricao = eventoCorrente?.tipoInscricao;
  const elegibilidade = eventoCorrente?.restrictEligibility;
  const campaignType = eventoCorrente?.campaignType;
  const isCampaignTypeEquipe = campaignType === "EQUIPE";

  const contadorCampanha = useCallback(() => {
    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "atividades.erro.dias.habilitados",
              "Erro obter lista de dias habilitados",
            ),
            message: i18n.message(
              "atividades.erro.dias.habilitados",
              "Erro obter lista de dias habilitados",
            ),
          },
        });
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "campanhaComecou", payload: false });
            const adminOrGestor =
              usuarioService.isAdmin() || usuarioService.isGestor();
            if (
              elegibilidade &&
              tipoInscricao === "ELEGIBILIDADE" &&
              isCampaignTypeEquipe &&
              !usuarioService.usuarioPossuiEquipe() &&
              !adminOrGestor
            ) {
              navigate("/selectTeam");
              return;
            }
            navigate("/home");
          }
        }
      }
    });
  }, [
    campanhaService,
    dispatch,
    elegibilidade,
    i18n,
    isCampaignTypeEquipe,
    navigate,
    tipoInscricao,
    usuarioService,
  ]);

  const handleAuth = useCallback(() => {
    let credenciais = {
      username: enrollment,
      password: null,
      campaign: code,
      extraInfo: [],
    };
    const usuario = usuarioService.usuarioCorrente();

    if (usuario?.enrollment === enrollment) {
      console.log("usuario ja logado", usuario);
      if (!usuario.legalTerm) {
        navigate("/accept");
        return;
      }
      dispatch({ type: "usuario", payload: true });
      dispatch({ type: "usuariologado", payload: usuario });
      dispatch({ type: "userTeamUuid", payload: usuario.team });
      dispatch({
        type: "usuarioAvatar",
        payload: usuario?.avatarDTO ? true : false,
      });
      dispatch({ type: "avatar", payload: usuario?.avatarDTO });
      contadorCampanha();
    } else {
      usuarioService.removeUsuarioCorrente();
      console.log("usuario nao logado", usuario);
      usuarioService.auth(credenciais, (erro, sucesso) => {
        if (erro) {
          navigate("/");
          return;
        }
        if (sucesso) {
          // Remove o identificador do convite, se tiver um
          usuarioService.removeConvite();
          dispatch({ type: "usuario", payload: true });
          dispatch({ type: "usuariologado", payload: sucesso });
          dispatch({ type: "userTeamUuid", payload: sucesso.team });
          dispatch({
            type: "usuarioAvatar",
            payload: sucesso?.avatarDTO ? true : false,
          });
          dispatch({ type: "avatar", payload: sucesso?.avatarDTO });
          if (!sucesso.legalTerm) {
            navigate("/accept");
            return;
          }

          contadorCampanha();
        }
      });
    }
  }, [enrollment, code, usuarioService, navigate, dispatch, contadorCampanha]);

  /** Pega as informações públicas da campanha antes de realizar a autenticação por causa do endpoint contadorCampanha e outras informações que devem ser setadas no localStorage e redux*/
  const getCampanhaAndAuth = useCallback(() => {
    window.localStorage.clear();
    let codeCampaign =
      campanhaService.verificaCodigoCampanhaPresenteParamsOuSubdominio(code);
    campanhaService.removeLocalStorageSeCampanhasForemDiferentes(code);
    campanhaService.definirCampanha(codeCampaign, null, (erro, sucesso) => {
      if (erro) {
        navigate("/campaign/select");
        return;
      }
      if (sucesso) {
        dispatch({
          type: "campanha/selecionar",
          payload: sucesso.messages,
        });
        dispatch({ type: "campanha", payload: sucesso });
        dispatch({ type: "template", payload: sucesso.template });
        dispatch({ type: "roletaCustomizacao", payload: sucesso?.roulette });
        dispatch({ type: "icone", payload: sucesso.icons });
        dispatch({ type: "usuario", payload: false });
        dispatch({ type: "usuariologado", payload: false });
        dispatch({
          type: "isRecurrentCampaign",
          payload: campanhaService.isRecurrentCampaign(sucesso),
        });
        dispatch({
          type: "clientCode",
          payload: campanhaService.getClientCode(),
        });

        handleAuth();
      }
    });
  }, [campanhaService, code, dispatch, handleAuth, navigate]);

  useEffect(() => {
    dispatch({ type: "usuario", payload: false });
    dispatch({ type: "usuariologado", payload: false });
    const cod = window.location.hostname?.split(".")[0];
    if (!cod) {
      return;
    }
    setCode(cod);
    if (enrollment && code) {
      getCampanhaAndAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return <></>;
}
export default LoginIndireto;
