import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ButtonRoletaStyle } from "./style";
import RoletaTooltip from "./RoletaTooltip";
import AtividadeEntity from "../AtividadeEntity";

function BotaoRoletaAgenda({
  navegarRoleta,
  scheduleCode,
  roleta,
  atividades,
}) {
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();

  const ticketsSorteados = useSelector((state) => state.ticketsSorteados);
  const [jaSorteado, setJaSorteado] = useState(false);
  const [mostrarRoleta, setMostrarRoleta] = useState(true);

  useEffect(() => {
    let matchedTicket = ticketsSorteados.find(
      (ticket) => ticket.scheduleUuid === scheduleCode,
    );

    if (matchedTicket) {
      setJaSorteado(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsSorteados]);

  // useEffect para verificar se todas as atividades da agenda não contam para o troféu
  // ou a agenda for do tipo prolongada, a roleta não será exibida

  useEffect(() => {
    const nenhumaAtividadeContaParaTrofeu = atividades.every((atividade) => {
      return atividade.trophyCount === false;
    });

    if (nenhumaAtividadeContaParaTrofeu) {
      setMostrarRoleta(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atividades]);

  const navegar = () => {
    if (roleta) {
      dispatch({ type: "scheduleCode", payload: scheduleCode });
      dispatch({ type: "tipoRoleta", payload: "DAILY_ROULETTE" });
      navegarRoleta(roleta, scheduleCode);
    }
  };

  const RoletaButton = () => {
    return (
      <RoletaTooltip
        text={i18n.message("roleta.habilitado", "Que tal tentar a sorte?")}
        position="left"
      >
        <div className="weex-l-game" aria-disabled="true" aria-hidden="true">
          <ButtonRoletaStyle
            tabIndex="-1"
            aria-hidden="true"
            aria-disabled="true"
            onClick={navegar}
            jaSorteado={jaSorteado}
          >
            <img
              src="/svgs-3.0/roleta.svg"
              alt={i18n.message(
                "alt.roleta.unlocked",
                "Ícone de uma roleta sorteadora",
              )}
            />
          </ButtonRoletaStyle>
        </div>
      </RoletaTooltip>
    );
  };

  const RoletaButtonBlocked = () => {
    return (
      <RoletaTooltip
        text={i18n.message(
          "roleta.desabilitado",
          "Faça todas as atividades para desbloquear a roleta",
        )}
        position="left"
      >
        <ButtonRoletaStyle disabled aria-disabled="true" aria-hidden="true">
          <img
            src="/svgs-3.0/roleta-disabled.svg"
            alt={i18n.message(
              "alt.roleta.locked",
              "Ícone de uma roleta em tons de cinza indicando que está desabilitado",
            )}
          />
        </ButtonRoletaStyle>
      </RoletaTooltip>
    );
  };

  const isEnable = () => {
    if (atividades.length > 0) {
      return atividades.reduce((isEnable, a) => {
        const atividade = new AtividadeEntity(a);
        return (
          (atividade.executed || atividade.type === "PRESENCIAL") && isEnable
        );
      }, true);
    }
    return false;
  };

  const roletaIsEnabled = () => {
    if (isEnable()) {
      return RoletaButton();
    }
    return RoletaButtonBlocked();
  };

  return <>{mostrarRoleta && roletaIsEnabled()}</>;
}

export default BotaoRoletaAgenda;
