import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { useStore } from "react-redux";
import Medalha from "../atividade/Medalha";
import { CardStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";
import TrofeuDoDia from "../dia/TrofeuDoDia";

/**
 * @param {Object} props
 * @param {Object} props.dia
 */
export default function Card({ dia }) {
  const i18n = useStore().getState().i18n;
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";
  const semData = !shouldShowScheduleDate
    ? "weex-l-card-trofeus-header-container__apenasIcone"
    : "";
  const dataTextual = dia?.day?.split(" ");
  const componentesData = dataTextual[0]?.split("-");
  const componentesDataFinal = componentesData[2] + "." + componentesData[1];
  const mesTextual = i18n.getNomeMesInteiro(componentesData[1]);

  const trofeusChunks = (activities, chunks) => {
    let final = [];
    let n = activities.length;
    for (let i = 0; i < n; i += chunks) {
      final = [...final, activities.slice(i, i + chunks)];
    }
    return final;
  };

  const buscarTrofeu = (tipo, activityId) => {
    let atividade = null;
    for (const atv of dia.activities) {
      if (atv.type === tipo && atv.activityId === activityId) {
        atividade = atv;
        break;
      }
    }
    return atividade;
  };

  let acts;
  if (
    dia.activities &&
    Array.isArray(dia.activities) &&
    dia.activities.length > 0
  ) {
    acts = dia.activities
      .filter((element) => buscarTrofeu(element.type, element.activityId))
      .sort((a, b) => a.order - b.order);
  } else {
    acts = dia.activities.filter((element) =>
      buscarTrofeu(element.type, element.activityId),
    );
  }

  let trofeusResults = trofeusChunks(acts, 5);
  let finalTrofeus = trofeusResults.map((items, key, index) => {
    let primeiroOuNao = items.length === 1 ? 21.5 : 20;
    return (
      <div
        key={key}
        className="weex-l-card-trofeus-atividades-body-container__link"
        style={{ width: primeiroOuNao * items.length + "%" }}
      >
        {items.map(function (el, index) {
          return (
            <Medalha
              key={index}
              url={el?.prize}
              element={el}
              activityAchieved={el.achieved}
              type={el.type}
              size={{ width: 100 / items.length - 3 + "%" }}
            />
          );
        })}
      </div>
    );
  });

  return (
    <CardStyle $achieved={dia.achieved}>
      <div className="weex-l-card-trofeus-atividades-header-container">
        <div
          // eslint-disable-next-line max-len
          className={`weex-l-card-trofeus-atividades-header-container__trofeu-dia ${semData}`}
        >
          <TrofeuDoDia url={dia?.prize} achieved={dia.achieved} />
          {shouldShowScheduleDate ? (
            <>
              <p aria-hidden="true">{componentesDataFinal}</p>
              <span className="weex__a11y--visuallyHidden">{`${componentesData[2]} ${mesTextual}`}</span>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="weex-l-card-trofeus-atividades-body-container">
        {finalTrofeus}
      </div>
    </CardStyle>
  );
}
