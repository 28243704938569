import packageInfo from "../../package.json";
import CampanhaService from "../services/CampanhaService";
import UsuarioService from "../services/UsuarioService";
import instance from "./Api";
import PwaService from "./PwaService";
import activitiesConfig from "../comps/config/activities.json";
import AgendaGlobalService from "./AgendaGlobalService";

export default class AgendaService {
  agendaGlobalService = new AgendaGlobalService();
  usuarioService = new UsuarioService();
  usuarioCorrente = this.usuarioService.usuarioCorrente();
  adminOrGestor =
    this.usuarioService.isAdmin() || this.usuarioService.isGestor();
  pwaService = new PwaService();
  campanhaService = new CampanhaService();
  agenda = null;

  agendaCorrente(callback) {
    this.cacheValido((erro, valido) => {
      if (valido.campaign === true) {
        callback(null, this.obterAgendaStorage());
      } else {
        if (this.pwaService.estouOnline()) {
          if (!this.usuarioCorrente) {
            this.usuarioService.logout(() => {
              callback(true, null);
            });
            return;
          }

          this.buscarAgenda(callback);
        } else {
          callback(null, this.obterAgendaStorage());
        }
      }
    });
  }

  buscarAgenda(callback) {
    let uuidParticipante = this.usuarioService?.getCodigoParticipante();
    if (!uuidParticipante) {
      console.error("Codigo do participante não encontrado em AgendaService");
    }
    instance
      .get(
        this.host() + "/api/v1/participant/" + uuidParticipante + "/schedule",
      )
      .then((response) => {
        this.setAgenda(response.data);
        window.localStorage.setItem(
          "validade-cache-agenda",
          new Date().getTime() + 2 * 60 * 60 * 1000,
        );
        callback(null, response.data);
      })
      .catch((error) => {
        if (error.status !== 401 && error.status !== 403) {
          callback(null, this.obterAgendaStorage());
        }
      });
  }

  agendaCorrentePromise() {
    window.localStorage.removeItem("agenda");
    window.localStorage.removeItem("agenda-global");
    return new Promise((resolve, reject) => {
      this.buscarAgenda((error, agenda) => {
        if (error) {
          reject(error);
        } else {
          resolve(agenda);
        }
      });
    });
  }

  invalidateCacheAndReload() {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) {
          if (name !== "last-campanha-check") caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }

  /**
   * @typedef {Object} ScheduleData
   * @property {string} trophyEnabled - URL da imagem do troféu habilitado global
   * @property {string} trophyDisabled - URL da imagem do troféu desabilitado global
   * @property {Array<Schedule>} schedule - Lista de agendamentos
   * @property {Array<Interest>} interests - Lista de interesses disponíveis
   * @property {boolean} pickedInterests - Indica se os interesses foram selecionados
   * @property {number} mandatoryInterestParticipant - Número de participantes obrigatórios para interesse
   * @property {Array<Schedule>} globalSchedule - Configurações globais do agendamento
   */

  /**
   * @typedef {Object} Schedule
   * @property {string} day - Data no formato YYYY-MM-DD
   * @property {string} dayOfWeek - Dia da semana em português (ex: "SEG", "TER")
   * @property {string} scheduleCode - Identificador único do agendamento
   * @property {string} trophyEnabled - URL da imagem do troféu habilitado para este agendamento
   * @property {string} trophyDisabled - URL da imagem do troféu desabilitado para este agendamento
   * @property {string} nome - Nome associado ao agendamento
   * @property {Array<Activity>} activities - Lista de atividades do agendamento
   * @property {Interest} interest - Interesse associado ao agendamento
   * @property {Array<Subtitle>} subtitles - Lista de legendas
   * @property {boolean} extendedSchedule - Indica se o agendamento é estendido
   */

  /**
   * @typedef {Object} Activity
   * @property {string} type - Tipo da atividade (ex: "NEW_HABIT")
   * @property {boolean} executed - Indica se a atividade foi executada
   * @property {string} trophyEnabled - URL da imagem do troféu habilitado para esta atividade
   * @property {string} trophyDisabled - URL da imagem do troféu desabilitado para esta atividade
   * @property {string} uuid - Identificador único da atividade
   * @property {number} order - Ordem/posição da atividade
   * @property {string} presentation - Conteúdo HTML da apresentação
   * @property {string|null} presentationCover - Imagem de capa da apresentação (se houver)
   * @property {string} title - Título da atividade
   * @property {string} name - Nome da atividade
   * @property {string|null} scoreType - Tipo de pontuação utilizada
   * @property {Subtitle|null} subtitle - Informações de legenda
   * @property {string|null} typeApresentation - Tipo de apresentação
   * @property {string|null} iframe - Conteúdo do iframe (se houver)
   * @property {string|null} mimeType - Tipo MIME do conteúdo
   * @property {boolean} showPresentation - Indica se a apresentação deve ser exibida
   * @property {Object|null} muralSettings - Configurações de exibição no mural
   * @property {boolean} trophyCount - Indica se a contagem de troféus está habilitada
   * @property {string|null} dynamicUuid - UUID dinâmico (se aplicável)
   */

  /**
   * @typedef {Object} Interest
   * @property {string} uuid - Identificador único do interesse
   * @property {string} name - Nome do interesse
   * @property {boolean} active - Indica se o interesse está ativo
   * @property {Object|null} units - Unidades associadas (se houver)
   */

  /**
   * @typedef {Object} Subtitle
   * @property {string|null} uuid - Identificador único da legenda
   * @property {string|null} subtitle - Conteúdo da legenda
   * @property {number|null} ordem - Ordem/posição da legenda
   */
  /**
   *
   * @returns {ScheduleData} Agendamento encontrado ou undefined
   */
  obterAgendaStorage() {
    let valorStorage = window.localStorage.getItem("agenda");
    if (valorStorage) {
      return JSON.parse(valorStorage);
    } else {
      return null;
    }
  }

  buscarGameAgenda(codigo) {
    let agenda = this.obterAgendaStorage();
    if (!agenda) {
      return null;
    }
    const dia = agenda.schedule.find((item) => item.scheduleCode === codigo);
    if (dia) {
      const game = dia.activities.find(
        (atividade) => atividade.type === "GAME",
      );
      return game;
    }
    return null;
  }

  getPickedInterests() {
    let value = this.obterAgendaStorage();
    if (value && value.pickedInterests) {
      return value.pickedInterests;
    } else {
      console.log("PickedInterests está null");
      return false;
    }
  }

  setAgenda(agenda) {
    if (agenda) {
      window.localStorage.setItem("agenda", JSON.stringify(agenda));
      this.agendaGlobalService.setAgendaGlobal(agenda?.globalSchedule);
    }
  }

  versaoAlterada(callback) {
    instance
      .get(
        this.host() +
          "/api/v1/weex/info?campaign=" +
          this.campanhaService.campanhaCorrente().uuid,
      )
      .then((response) => {
        window.localStorage.setItem(
          "validade-cache-agenda",
          new Date().getTime() + 30 * 60 * 1000,
        );
        let versoes = {};
        versoes.campaign = !(
          (response.data.campaign === null &&
            this.campanhaService.campanhaCorrente().version === null) ||
          (response.data.campaign != null &&
            this.campanhaService.campanhaCorrente().version !== null &&
            response.data.campaign ===
              this.campanhaService.campanhaCorrente().version)
        );
        versoes.pwa = !(
          response.data.pwa === null ||
          response.data.pwa === packageInfo.version
        );

        callback(null, versoes);
      })
      .catch((error) => {
        if (error.status !== 401 && error.status !== 403) {
          callback(null, { campaign: false, pwa: false });
        }
      });
  }

  cacheValido(callback) {
    if (this.obterAgendaStorage()) {
      let valorStorage = window.localStorage.getItem("validade-cache-agenda");
      if (valorStorage) {
        let valido = parseInt(valorStorage) > new Date().getTime();
        if (!valido) {
          this.versaoAlterada((erro, versoes) => {
            callback(null, this.preencheCacheValido(versoes));
          });
        } else {
          callback(null, { campaign: true, pwa: true });
        }
      } else {
        this.versaoAlterada((erro, versoes) => {
          callback(null, this.preencheCacheValido(versoes));
        });
      }
    } else {
      callback(null, { campaign: false, pwa: true });
    }
  }

  atividadesPorSubtitulos(subtitles, agendaCorrente) {
    return subtitles.map((subtitle) => {
      let bySubtitle = {
        subtitle: subtitle,
        activities: agendaCorrente.activities.filter(
          (atividade) =>
            atividade.subtitle.uuid === subtitle.uuid &&
            atividade.type !== "GAME",
        ),
      };
      return bySubtitle;
    });
  }

  atualizarAgendaGame(codigo, codigoAtividade, callback) {
    let agenda = this.obterAgendaStorage();
    if (!agenda) {
      //não deveria ocorrer
      this.agendaCorrente(callback);
      return;
    }
    const dia = agenda.schedule.find((item) => item.scheduleCode === codigo);
    if (dia) {
      const atividade = dia.activities.find(
        (atividade) => atividade.uuid === codigoAtividade,
      );
      if (atividade) {
        atividade.executed = true;
        this.setAgenda(agenda);
        callback(null, true);
        return;
      }
      return callback(true, null);
    }
  }

  /**
   * Atualiza a agenda com base nas informações da atividade fornecida.
   * @param {string} codigo - O código do agendamento a ser atualizado.
   * @param {string} codigoAtividade - O código da atividade a ser atualizada.
   * @param {string} tipoAtividade - O tipo de atividade.
   * @param {number} numeroQuestoes - O número de questões (para atividades FACT_OR_FAKE).
   * @param {number} acertos - O número de respostas corretas (para atividades FACT_OR_FAKE).
   * @param {function} callback - Função de retorno a ser chamada após a atualização.
   */
  atualizarAgenda(
    codigo,
    codigoAtividade,
    tipoAtividade,
    numeroQuestoes,
    acertos,
    callback,
  ) {
    // Recupera a agenda atual do armazenamento
    this.agenda = this.obterAgendaStorage();
    // Se nenhuma agenda for encontrada, chama agendaCorrente e retorna
    if (!this.agenda) {
      this.agendaCorrente(callback);
      return;
    }

    // Se a atividade for um GAME, trata separadamente e retorna
    if (tipoAtividade === "GAME") {
      this.atualizarAgendaGame(codigo, codigoAtividade, callback);
      return;
    }

    // Encontra o item da agenda com o código correspondente
    const dia = this.agenda.schedule.find(
      (item) => item.scheduleCode === codigo,
    );

    // agenda não encontrada
    if (!dia) {
      callback(true, null);
      return;
    }

    // Verifica se o item da agenda tem subtitulo
    const hasSubtitles = dia.subtitles?.length > 0;

    // Atualiza a agenda com base na presença ou não de subtitulo
    if (hasSubtitles) {
      this.atualizarAgendaComSubtitulos(
        dia,
        codigoAtividade,
        tipoAtividade,
        numeroQuestoes,
        acertos,
        callback,
      );
    } else {
      this.atualizarAgendaSemSubtitulos(
        dia,
        codigoAtividade,
        tipoAtividade,
        numeroQuestoes,
        acertos,
        callback,
      );
    }

    // Salva a agenda atualizada
    this.setAgenda(this.agenda);
  }

  /**
   * Atualiza a agenda para itens da agenda com subtitulos.
   * @param {Object} dia - O item da agenda a ser atualizado.
   * @param {string} codigoAtividade - O código da atividade a ser atualizada.
   * @param {string} tipoAtividade - O tipo de atividade.
   * @param {number} numeroQuestoes - O número de questões (para atividades FACT_OR_FAKE).
   * @param {number} acertos - O número de respostas corretas (para atividades FACT_OR_FAKE).
   * @param {function} callback - Função de retorno a ser chamada após a atualização.
   */
  atualizarAgendaComSubtitulos(
    dia,
    codigoAtividade,
    tipoAtividade,
    numeroQuestoes,
    acertos,
    callback,
  ) {
    // Obtém atividades organizadas por subtitulo
    const activitiesWithSubtitles = this.atividadesPorSubtitulos(
      dia.subtitles,
      dia,
    );

    // Itera pelas subtitulo e suas atividades
    for (const bySubtitle of activitiesWithSubtitles) {
      // Encontra a atividade correspondente
      const atividade = bySubtitle.activities.find(
        (a) => a.uuid === codigoAtividade,
      );
      if (atividade) {
        // Atualiza a atividade
        this.atualizarAtividade(
          atividade,
          tipoAtividade,
          numeroQuestoes,
          acertos,
        );
        if (!dia.extendedSchedule) {
          const atividadesBloco = bySubtitle.activities;
          this.nextAtividade(dia, atividadesBloco, atividade);
        }
        // Chama o callback com sucesso
        this.setAgenda(this.agenda);
        callback(null, true);
        return;
      }
    }

    // Se nenhuma atividade correspondente for encontrada, chama o callback com erro
    callback(true, null);
  }

  /**
   * Atualiza a agenda para itens da agenda sem subtitulos.
   * @param {Object} dia - O item da agenda a ser atualizado.
   * @param {string} codigoAtividade - O código da atividade a ser atualizada.
   * @param {string} tipoAtividade - O tipo de atividade.
   * @param {number} numeroQuestoes - O número de questões (para atividades FACT_OR_FAKE).
   * @param {number} acertos - O número de respostas corretas (para atividades FACT_OR_FAKE).
   * @param {function} callback - Função de retorno a ser chamada após a atualização.
   */
  atualizarAgendaSemSubtitulos(
    dia,
    codigoAtividade,
    tipoAtividade,
    numeroQuestoes,
    acertos,
    callback,
  ) {
    // Filtra as atividades do tipo GAME
    const atividadesSemGame = dia.activities.filter(
      (atv) => atv.type !== "GAME",
    );
    // Encontra a atividade correspondente
    const atividade = atividadesSemGame.find(
      (a) => a.type === tipoAtividade && a.uuid === codigoAtividade,
    );

    if (atividade) {
      // Atualiza a atividade
      this.atualizarAtividade(
        atividade,
        tipoAtividade,
        numeroQuestoes,
        acertos,
      );

      // Move para a próxima atividade se a agenda estiver desbloqueada ou o usuário for admin/gestor
      if (!dia.extendedSchedule) {
        this.nextAtividade(dia, atividadesSemGame, atividade);
      }
      this.setAgenda(this.agenda);

      // Chama o callback com sucesso
      callback(null, true);
    } else {
      // Se nenhuma atividade correspondente for encontrada, chama o callback com erro
      callback(true, null);
    }
  }

  /**
   * Atualiza as propriedades de uma atividade com base em seu tipo.
   * @param {Object} atividade - A atividade a ser atualizada.
   * @param {string} tipoAtividade - O tipo de atividade.
   * @param {number} numeroQuestoes - O número de questões (para atividades FACT_OR_FAKE).
   * @param {number} acertos - O número de respostas corretas (para atividades FACT_OR_FAKE).
   */
  atualizarAtividade(atividade, tipoAtividade, numeroQuestoes, acertos) {
    if (tipoAtividade === "FACT_OR_FAKE") {
      // Atualiza propriedades específicas de FACT_OR_FAKE
      atividade.numeroQuestoes = numeroQuestoes;
      atividade.acertos = acertos;
    } else {
      // Marca outras atividades como executadas
      atividade.executed = true;
    }
  }
  nextAtividade(dia, atividades, atividade) {
    atividade.nextActivity = null;
    atividade.nextActivityExecuted = null;
    /**
     *
     * buscar a proxima atividade no mesmo bloco de atividades (subtitulo)
     */
    const proximaAtividadeNoMesmoBloco =
      this.buscarProximaAtividadeNoMesmoBloco(atividades, atividade);
    if (proximaAtividadeNoMesmoBloco) {
      this.setProximaAtividade(dia, atividade, proximaAtividadeNoMesmoBloco);
      return;
    }

    /**
     * buscar a proxima atividade no proximo bloco de atividades (subtitulo) do mesmo dia
     */
    const proximaAtividadeNoProximoBloco =
      this.buscarProximaAtividadeNoProximoBloco(dia, atividade);
    if (proximaAtividadeNoProximoBloco) {
      this.setProximaAtividade(dia, atividade, proximaAtividadeNoProximoBloco);
      return;
    }

    /**
     * buscar a proxima atividade nas agendas da campanha retorna a proxima atividade não executada fora da agenda prolongada
     * */
    const { proximaAtividadeNasAgendas, proximaScheduleCode } =
      this.buscarProximaAtividadeNasAgendas();
    if (proximaAtividadeNasAgendas) {
      this.setProximaAtividade(
        dia,
        atividade,
        proximaAtividadeNasAgendas,
        proximaScheduleCode,
      );
    }

    // Se chegou até aqui, não há mais atividades a serem executadas
  }

  buscarProximaAtividadeNoMesmoBloco(atividades, atividade) {
    // Encontrar a próxima atividade não executada no mesmo bloco de atividades
    const proximaAtividade = atividades.find(
      (atv) => !atv.executed && atv.uuid !== atividade.uuid,
    );

    // Retornar a próxima atividade não executada, se existir
    return proximaAtividade;
  }

  buscarProximaAtividadeNoProximoBloco(dia, atividade) {
    let proximaAtividade = null;
    for (const subtitle of dia.subtitles) {
      // Ignorar o bloco de atividades que contém a atividade atual
      if (subtitle.uuid === atividade.subtitle.uuid) {
        continue;
      }

      // Buscar atividades não executadas no bloco de atividades
      const atividadeNaoExecutada = dia.activities.find(
        (atv) => !atv.executed && atv.subtitle.uuid === subtitle.uuid,
      );

      // Se encontrou atividade não executada, definir e sair do loop
      if (atividadeNaoExecutada) {
        proximaAtividade = atividadeNaoExecutada;
        break;
      }
    }
    return proximaAtividade;
  }

  buscarProximaAtividadeNasAgendas() {
    let proximaAtividadeNasAgendas = null;
    let proximaScheduleCode = null;
    for (const dia of this.agenda.schedule) {
      if (dia === null) {
        // não deveria acontecer nunca
        console.error("Dia vázio");
        continue; // Ignorar dias vazios
      }

      // Ignorar agenda prolongada
      if (dia.extendedSchedule) {
        continue;
      }

      if (this.hasAgendaDesbloqueada(dia)) {
        // Encontrar a primeira atividade não executada no dia
        const atividadeNaoExecutada = dia.activities.find(
          (atv) => !atv.executed && atv.type !== "GAME",
        );

        // Se encontrou atividade não executada, definir e sair do loop
        if (atividadeNaoExecutada) {
          proximaAtividadeNasAgendas = atividadeNaoExecutada;
          proximaScheduleCode = dia.scheduleCode;
          break; // Use 'break' para sair do loop quando encontrar a atividade
        }
      }
    }

    return {
      proximaAtividadeNasAgendas,
      proximaScheduleCode,
    };
  }

  setProximaAtividade(dia, atividade, proximaAtividade, proximaScheduleCode) {
    const { route } = activitiesConfig.find(
      (a) => a.type === proximaAtividade.type,
    );
    const scheduleCode = proximaScheduleCode || dia.scheduleCode;
    proximaAtividade.scheduleCode = scheduleCode;
    proximaAtividade.route = route;
    // Fazer uma cópia profunda de `proximaAtividade` usando JSON.parse e JSON.stringify
    atividade.nextActivity = JSON.parse(JSON.stringify(proximaAtividade));

    atividade.nextActivityExecuted = proximaAtividade.executed;
  }

  /**
   * retornar true se a agenda é do dia atual ou anterior
   */
  hasAgendaDesbloqueada(dia) {
    try {
      if (dia && dia.day && typeof dia.day === "string") {
        const [year, month, day] = dia.day?.split("-").map(Number);

        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          const diaAgenda = new Date(year, month - 1, day);

          if (diaAgenda instanceof Date && !isNaN(diaAgenda.getTime())) {
            const today = new Date();
            const mesmaData =
              diaAgenda.getDate() === today.getDate() &&
              diaAgenda.getMonth() === today.getMonth() &&
              diaAgenda.getFullYear() === today.getFullYear();
            return mesmaData;
          }
        }
      }
      return false;
    } catch (error) {
      console.error("Erro ao analisar a data:", error);
      return false;
    }
  }

  atualizarInteresse(interesse) {
    let valorStorage = window.localStorage.getItem("agenda");
    let valorStorageValidade = window.localStorage.getItem(
      "validade-cache-agenda",
    );
    if (interesse === true && valorStorage && valorStorageValidade) {
      window.localStorage.removeItem("agenda");
      window.localStorage.removeItem("validade-cache-agenda");
    }
  }

  preencheCacheValido(versoesAlteradas) {
    let cacheValido = { campaign: true, pwa: true };
    if (versoesAlteradas.campaign != null) {
      cacheValido.campaign = !versoesAlteradas.campaign;
    } else {
      cacheValido.campaign = false;
    }
    if (versoesAlteradas.pwa != null) {
      cacheValido.pwa = !versoesAlteradas.pwa;
    } else {
      cacheValido.pwa = false;
    }
    return cacheValido;
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }

  atualizarAtividadeAgendaExecutada(codigoAtividade, executada) {
    let agenda = this.obterAgendaStorage();
    let atividadeEncontrada = agenda?.schedule
      .flatMap((dia) => dia.activities)
      .find((atividade) => atividade.uuid === codigoAtividade);

    if (atividadeEncontrada) {
      atividadeEncontrada.executed = executada;
    }
    this.setAgenda(agenda);
  }

  buscarAgendaPorCodigo(codigo) {
    let agenda = this.obterAgendaStorage();
    if (!agenda) {
      return null;
    }
    return agenda.schedule.find((item) => item.scheduleCode === codigo);
  }
}
